import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home, Signin, Signup, Profile, ResetPassWord, IsVerified } from './pages';
import { ProtectedRoute } from './helpers/routes'
import { useImmerReducer } from "use-immer"
import StateContext from './context/StateContext'
import DispatchContext from './context/DispatchContext'
import { ourReducer, initialState } from './context/context'
import * as ROUTES from './constants/routes';
import { ErrorHandler, HelmetMetaData } from './components'

export default function App() {

  const [state, dispatch] = useImmerReducer(ourReducer, initialState)

  return (
    <Router>
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
          <HelmetMetaData></HelmetMetaData>
          <ErrorHandler>
            <Switch>
              <Route exact path={ROUTES.HOME}>
                <Home />
              </Route>
              <Route exact path={ROUTES.SIGNIN}>
                <Signin />
              </Route>
              <Route exact path={ROUTES.SIGNUP}>
                <Signup />
              </Route>
              <Route exact path={`/:slug-:id`}>
                <Home />
              </Route>
              <ProtectedRoute exact path={ROUTES.PROFIL} user={state.loggedIn}>
                <Profile />
              </ProtectedRoute>
              <Route exact path={`${ROUTES.RESETPASSWORD}/:token`}>
                <ResetPassWord />
              </Route>
              <Route exact path={`${ROUTES.ISVERIFIED}`}>
                <IsVerified />
              </Route>
              { /* TODO: protected route*/}
              <Route exact path={ROUTES.ADMIN}>
                <p>ADMINISTRATION</p>
              </Route>
              <Route path="*">
                <Home />
              </Route>
            </Switch>
          </ErrorHandler>
        </DispatchContext.Provider>
      </StateContext.Provider>
    </Router >
  );
}
