import React, { useState, useEffect, useContext } from 'react';
import { Header } from '../components';
import StateContext from '../context/StateContext'
import DispatchContext from '../context/DispatchContext'
import logo from '../assets/logos/logo_cgt.svg'
import searchIcon from '../assets/svg/search-icon.svg'
import markerGrey from '../assets/svg/marker-grey-icon.svg'
import chevronDown from '../assets/svg/chevron-down-icon.svg'
import userIcon from '../assets/svg/user-icon.svg'
import userIconActive from '../assets/svg/user-icon-active.svg'
import loginIcon from '../assets/svg/login.svg'
import closeIcon from '../assets/svg/close.svg'
import * as ROUTES from '../constants/routes';
import breakpoint from '../constants/breakpoints'

export function HeaderContainer({ children }) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)
    const [fullscreen, setFullscreen] = useState(false)
    let [title, setTitle] = useState('')
    let [location, setLocation] = useState('')

    useEffect(() => {
        if (appState.resetTitle > 0)
            setTitle('')
    }, [appState.resetTitle])

    useEffect(() => {
        appDispatch({ type: "setTitleSearch", value: "" })
    }, [appDispatch])

    return (
        <>
            <Header color="faded" light toggleable="lg">
                <Header.Frame>
                    <Header.LogoContainer to={ROUTES.HOME} src={logo} alt="Cgt Logo" />
                    <Header.Search search={searchIcon} marker={markerGrey} fullscreen={fullscreen} setFullscreen={setFullscreen} title={title} setTitle={setTitle} location={location} setLocation={setLocation} />
                    <Header.Menu chevron={chevronDown} user={userIcon} userActive={userIconActive} loginIcon={loginIcon} />
                </Header.Frame>
                {children}
            </Header>
            {window.innerWidth < parseInt(breakpoint.size.md) &&
                <Header.Fullscreen search={searchIcon} marker={markerGrey} fullscreen={fullscreen} setFullscreen={setFullscreen} logo={logo} close={closeIcon} title={title} setTitle={setTitle} location={location} setLocation={setLocation} />
            }
        </>
    );
}