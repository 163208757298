import axios from 'axios';

const instance = axios.create({
    withCredentials: true
})


export const signup = async (mail, password) => {
    try {
        // redirectUrl
        const redirectUrl =
        "?redirectUrl=" +
        encodeURIComponent(window.location.protocol + "//" + window.location.host + "/isverified");
        const response = await instance.post(`${process.env.REACT_APP_BACK_URL}/signup${redirectUrl}`, { mail, password });
        return response
    } catch (error) {
        throw error;
    }
};

export const signin = async (mail, password) => {
    try {
        const response = await instance.post(`${process.env.REACT_APP_BACK_URL}/signin`, { mail, password });
        const { xsrfToken, user } = response.data;
        localStorage.setItem('xsrfToken', JSON.stringify(xsrfToken));
        const userSafe = {_id: user['_id'], login: user['login']};
        localStorage.setItem('user', JSON.stringify(userSafe));
        return response
    } catch (error) {
        throw error;
    }
};

export const logout = async () => {
    const savedToken = JSON.parse(localStorage.getItem('xsrfToken'));
    let config = {
        headers: {
            'x-xsrf-token': savedToken
        }
    }
    try {
        const response = await instance.post(`${process.env.REACT_APP_BACK_URL}/logout`, '', config);
        return response
    } catch (error) {
        throw error;
    }
};

export const requestNewPassword = async (mail) => {
    try {
      // redirectUrl
      const redirectUrl =
        "?redirectUrl=" +
        encodeURIComponent(window.location.protocol + "//" + window.location.host + "/reset/");
      const response = await instance.post(`${process.env.REACT_APP_BACK_URL}/request/newpassword${redirectUrl}`, { mail } );
      return response;
    } catch (error) {
      throw error;
    }
};

export const resetPassword = async (password, token) => {
    try {
      const response = await instance.post(`${process.env.REACT_APP_BACK_URL}/reset/password`, { password, token } );
      return response;
    } catch (error) {
      throw error;
    }
  };