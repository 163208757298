import React, { useContext, useEffect, useState, useRef } from 'react';
import { Map } from '../components';
import { ListContainer } from './list'
import { InfoContainer } from './info'
// import infos from '../fixtures/infos.json';
import StateContext from '../context/StateContext'
import DispatchContext from '../context/DispatchContext'
import { useLocation, useParams } from 'react-router-dom'
import * as infoServices from '../services/info_services'

export function MapContainer({ children }) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)
    const location = useLocation();
    const { id } = useParams();
    const skipEffect = useRef(false);
    const skipQuery = useRef(false);
    const firstUpdate = useRef(true);
    const titleRef = useRef('');

    let [infos, setInfos] = useState([])

    // geoJSON format longitude / latitude
    const getGeoJSONCoordinates = (bounds) => {
        let { geoJsonBounds } = bounds
        return geoJsonBounds
    }

    useEffect(() => {
        if (titleRef.current !== appState.titleSearch && appState.titleSearch.length > 2) {
            skipQuery.current = true
            titleRef.current = appState.titleSearch
            let bound = new window.google.maps.LatLngBounds()
            infoServices.getInfo(`?search={"title": { "$regex": "${appState.titleSearch}" , "$options" : "i" }}`)
                .then((data) => {
                    for (let info of data.infos) {
                        let coord = info.locationGeoJSON.coordinates
                        // GeoJson Format lng / lat
                        bound.extend(new window.google.maps.LatLng(coord[1], coord[0]));
                    }
                    if (data.infos.length > 0)
                        appDispatch({ type: "setSearchBounds", value: bound })
                    else {
                        appDispatch({ type: "noSearchBounds" })
                        setInfos([])
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    }, [appState.titleSearch, appDispatch])

    useEffect(() => {
        const getConditions = (bounds) => {
            let conditions = '?'
            let geowithin = {
                "locationGeoJSON": {
                    "$geoWithin": {
                        "$geometry": {
                            "type": "Polygon",
                            "coordinates": getGeoJSONCoordinates(bounds)
                        }
                    }
                }
            }
            conditions += `geowithin=${JSON.stringify(geowithin)}`
            conditions += `&dateprint=true`
            if (appState.titleSearch && appState.titleSearch !== '')
                conditions += `&search={"title": { "$regex": "${appState.titleSearch}" , "$options" : "i" }}`
            else if (appState.locationSearch && appState.locationSearch !== '') {
                conditions += `&search={"address": { "$regex": "${appState.locationSearch}" , "$options" : "i" }}`
                titleRef.current = ''
            }
            return conditions
        }
        if (skipQuery.current === true) {
            skipQuery.current = false
            return
        }
        if (appState.bounds || appState.newInfoStatus === 200) {
            infoServices.getInfo(getConditions(appState.bounds))
                .then((res) => {
                    setInfos(res.infos)
                    appDispatch({ type: "setMarkers", value: res.infos })
                })
                .catch((e) => {
                    console.log(e)
                    appDispatch({ type: "resetTitle" })
                    appDispatch({ type: "setTitleSearch", value: "" })

                })
        }
    }, [appState.bounds, appState.newInfoStatus, appState.titleSearch, appState.locationSearch, appDispatch]);

    useEffect(() => {
        if (id && firstUpdate.current) {
            let getId
            if (id.split("-").length > 1)
                getId = id.split("-")[id.split("-").length - 1]
            else
                getId = id
            firstUpdate.current = false
            skipEffect.current = true
            infoServices.getInfo()
                .then((res) => {
                    let activeInfo = res.infos.find(info => info['_id'] === getId)
                    if (activeInfo)
                        setTimeout(() => {
                            appDispatch({ type: "markerClicked", value: activeInfo })
                        }, 200);
                })
                .catch((e) => {
                    console.log(e)
                })
        }
        else if (id)
            skipEffect.current = true
    }, [id, appDispatch]);

    useEffect(() => {
        if (location.pathname.includes("info") && skipEffect.current === false)
            appDispatch({ type: "addInfo", value: true })
    }, [location.pathname, appDispatch]);

    useEffect(() => {
        firstUpdate.current = false
    }, []);

    return (
        <Map.Container>
            <Map>
            </Map>
            {appState.infoOpen === false
                ? <ListContainer />
                : <InfoContainer />
            }
            {children}
        </Map.Container>
    );
}