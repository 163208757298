export const resources = {
    fr: {
        translation: {
            "Mon Compte": "Mon Compte",
            "Mon Profil": "Mon Profil",
            "Deconnecter": "Me déconnecter",
            "Signature 1": "Les mardis",
            "Signature 2": "de la colère",
            "Recherche Placeholder": "Que recherchez-vous ?",
            "Retour Resultats": "Retour aux résultats",
            "Publie le": "Publié le",
            "Par": "par",
            "Bouton Ajouter": "Ajouter une info",
            "Connecter": "Me connecter",
            "Email": "E-Mail",
            "Password": "Mot de passe",
            "Email Placeholder": "Entrez votre adresse e-mail",
            "Password Placeholder": "Entrez votre mot de passe",
            "Password Oublie": "Mot de passe oublié ?",
            "Pas De Compte": "Pas de compte ?",
            "Creer Compte": "En créer un maintenant",
            "Email Invalide": "Adresse mail non valide",
            "Signin Erreur": "E-mail ou mot de passe incorrect",
            "Signin Success": "Vous avez été connecté avec succès !",
            "Bouton Registre": "Allons-y!",
            "Password Invalide": "8 caractères minimum",
            "Registre Erreur": "E-mail existe déjà",
            "Registre Success": "Compte créé avec succès ! Un e-mail avec un lien de vérification vous a été envoyé.",
            "Creation Compte": "Créer un compte",
            "Profil Header": "Bonjour",
            "Compte Valide": "Compte validé",
            "Compte Pending": "Compte en attente",
            "Compte Blocked": "Compte bloqué",
            "Mes Infos": "Mes infos persos",
            "Nom": "Nom",
            "Prenom": "Prénom",
            "Profession": "Profession",
            "Societe": "Société",
            "Bouton Profil": "Mettre à jour mes infos",
            "Supprimer Compte": "Supprimer mon compte",
            "Bouton Valider": "Valider et ajouter l’info",
            "Ajouter Info": "Ajouter une info",
            "Titre": "Titre",
            "Titre Placeholder": "Entrez un titre pour cette information",
            "Description": "Description",
            "Description Placeholder": "Entrez le contenu de votre texte",
            "Adresse": "Adresse",
            "Adresse Placeholder": "Cliquez sur la carte ou saisissez l’adresse",
            "Entreprise": "Entreprise",
            "Syndicat": "Syndicat",
            "Participant": "Nombre de participant",
            "Date Debut": "Date de début",
            "Date Fin": "Date de fin",
            "Media": "Media (JPG ou PNG)",
            "Piece Jointe": "Pièce-jointe (PDF)",
            "Parcourir": "Parcourir",
            "Ancun fichier": "Ancun fichier sélectionné",
            "Deconnecte": "Vous êtes déconnecté-es !",
            "Info Deconnecte": "Il faut être connecté-e pour poster des infos !",
            "Connecter ici": "Connecter ici",
            "Info Ajoutee": "Info ajoutée !",
            "Info Ajoutee Text": "Votre info a été ajoutée et sera visible sur la carte d’ici quelques instants. Merci encore de votre contribution.",
            "Erreur Info": "Erreur serveur !",
            "Erreur Info Text": "Oups, Une erreur est survenue. Veuillez recommencer ultérieurement",
            "Title Invalide": "Titre non valide",
            "Adresse Invalide": "Adresse non valide",
            "Erreur Credentials": "Il semble que vous êtes déconnecté!",
            "Modifer": "Modifier",
            "Bouton Valider Edit": "Valider et modifier l'info",
            "Modifier Info": "Modifier une info",
            "Info Modifiee": "Info modifiée !",
            "Info Modifiee Text": "Votre info a été modifiée et sera visible sur la carte d’ici quelques instants. Merci encore de votre contribution.",
            "Fichier Erreur": "Le fichier chargé ne correspond pas aux formats de fichiers autorisés",
            "invalid_file_size": "Le poids du fichier chargé dépasse la limite autorisée",
            "Pieces Jointes": "Pièce(s)-jointe(s)",
            "Mots Cle": "Mots clé",
            "Modif Password": "Modification de mot de passe",
            "Reset Password": "Réinitialiser mot de passe",
            "Reset Password Success": "Un email avec les instructions pour modifier votre mot de passe vient de vous être envoyé",
            "Lancer Recherche": "Lancer la recherche",
            "user_is_forbidden": "Accès non autorisé",
            "Pas de Resultats": "Désolé, aucun résultat ne correspond à votre recherche.",
            "expired_verify_token": "Le lien de demande de réinitialisation de mot de passe à expiré.",
            "reset_password_done": "Votre mot de passe a été réinitialisé.",
            "Debut Pub": "Début de la publication",
            "Fin Pub": "Fin de la publication",
            "Info CGT": "Info CGT",
            "user_not_found" : "L'utilisateur non trouvé ! Vérifiez l'e-mail ou créez un compte",
            "account_verified": "Votre email a été vérifié avec succès. Un administrateur va valider votre compte.",
            "account_already_verified": "Le compte a déjà été vérifié.",
            "form_on_error": "Les champs avec * sont obligatoires.",
            "info_file_size": " - poids maximum {{ size }}",
            "delete": "supprimer"
        }
    }
};