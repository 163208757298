import React, { useContext, useEffect, useState, useRef } from 'react';
import { Info } from '../components'
import StateContext from '../context/StateContext'

export function InfoContainer({ children }) {

    const appState = useContext(StateContext)
    let [isEdit, setIsEdit] = useState(false)
    let [scrollHeight, setScrollHeight] = useState('100%')
    let [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const infoRef = useRef(null);

    useEffect(() => {
        if (appState.infoData)
            setIsEdit(true)
    }, [appState.infoData])

    useEffect(() => {
        const updateWindowDimensions = () => {
            setWindowWidth(window.innerWidth)
        };

        window.addEventListener("resize", updateWindowDimensions);

        return () => window.removeEventListener("resize", updateWindowDimensions)

    }, []);

    useEffect(() => {
        if (infoRef && infoRef.current) {
            let height = infoRef.current.clientHeight
            setScrollHeight(height + "px")
        }
    }, [windowWidth])

    return (
        <Info infoRef={infoRef} scrollHeight={scrollHeight}>
            <Info.Return isEdit={isEdit} />
            {appState.loggedIn === true
                ?
                <Info.Container allData={appState.infoData} isEdit={isEdit} />
                :
                <Info.LoggedOut />
            }
            {children}
        </Info>
    );
}