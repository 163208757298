import React, { useState, useEffect, useContext, useRef } from 'react';
import { CustomAlert } from './styles/errorhandler'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import StateContext from '../../context/StateContext'
import DispatchContext from '../../context/DispatchContext'
import { errorHelper } from '../../helpers/error'
import * as ROUTES from '../../constants/routes';
import * as userServices from '../../services/user_services';


export default function ErrorHandler({ children }) {

    const { t } = useTranslation();
    let history = useHistory();
    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)
    const [isOpen, setIsOpen] = useState(false)
    const alertText = useRef('');

    useEffect(() => {
        if (appState.error) {
            let { showError, logout, errorText, refreshToken } = errorHelper(appState.error)
            alertText.current = errorText
            if (showError === true)
                setIsOpen(true)
            if (logout === true)
                setTimeout(() => {
                    history.push(ROUTES.SIGNIN);
                    appDispatch({ type: "logout" })
                }, 2000);
            if (refreshToken === true)
                userServices.refreshToken()
        }
        else
            setIsOpen(false)
    }, [appState.error, appDispatch, history])

    useEffect(() => {
        return history.listen(() => {
            setIsOpen(false)
        })
    }, [history])

    return (
        <>
            <CustomAlert color="danger" isOpen={isOpen} onClick={() => setIsOpen(false)}>{t(alertText.current)}</CustomAlert>
            {children}
        </>
    )
}