import React, { useState, useContext, useEffect, useReducer, useRef } from 'react';
import { InfoWrapper, Toggle, AddButton, Return, ScrollContainer, InfoTitle, Label, InfoInput, InputContainer, InfoTextArea, FormContainer, InputFile, Spinner, MessageContainer, MessageText, SigninLink, InputFeedback, SuggestionsList, CreateSelect, Switch, Slider, CreateAlert, DeleteButton } from './styles/info';
import { ReactComponent as ChevronLeft } from '../../assets/svg/chevron-left-icon.svg';
import { useTranslation } from 'react-i18next';
import { Img } from '@bootstrap-styled/v4';
import plusIcon from '../../assets/svg/plus-circle-icon.svg'
import successIcon from '../../assets/svg/success-circle-icon.svg'
import errorIcon from '../../assets/svg/times-red-icon.svg'
import DispatchContext from '../../context/DispatchContext'
import StateContext from '../../context/StateContext'
import Axios from "axios"
import * as ROUTES from '../../constants/routes';
import * as infoServices from '../../services/info_services'
import * as locServices from '../../services/loc_services'


export default function Info({ children, infoRef, scrollHeight }) {

    const [isHidden, setIsHidden] = useState(false)

    return (
        <InfoWrapper isHidden={isHidden} ref={infoRef}>
            <ScrollContainer style={{ height: scrollHeight }}>
                {children}
            </ScrollContainer>
            <Toggle onClick={() => setIsHidden(!isHidden)} isHidden={isHidden}>
                <ChevronLeft />
            </Toggle>
        </InfoWrapper>
    )
}

Info.Return = function InfoReturn({ isEdit }) {

    const { t } = useTranslation();
    const appDispatch = useContext(DispatchContext)

    const returnResults = () => {
        if (isEdit === true)
            appDispatch({ type: "editInfo", value: { status: false, data: null } })
        else
            appDispatch({ type: "addInfo", value: false })
    }

    return (
        <Return onClick={returnResults}><ChevronLeft />{t('Retour Resultats')}</Return>
    )
};

Info.Container = function InfoContainer({ allData, isEdit }) {

    const { t } = useTranslation();

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)
    const firstUpdate = useRef(true);

    const initialState = {
        title: '',
        description: '',
        address: '',
        entreprise: '',
        syndicat: '',
        participant: 0,
        startDate: '',
        endDate: '',
        datePub: '',
        dateEndPrint: '',
        lat: '',
        lng: '',
        pictureId: '',
        docId: '',
        image: '',
        pdf: '',
        tag: '',
        isMine: true,
        alertErrorForm: false,
        imageId: '',
    }

    function reducer(state, { field, value }) {
        if (field === "all")
            return {
                ...value
            }
        return {
            ...state,
            [field]: value
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const { title, description, address, entreprise, syndicat, participant, lat, lng, startDate, endDate, image, pdf, tag, datePub, dateEndPrint, isMine, imageId, pdfId } = state

    let [isDisabled, setIsDisabled] = useState(true)
    let [submitted, setSubmitted] = useState(false)
    let [validTitle, setValidTitle] = useState(true)
    let [validAddress, setValidAddress] = useState(true)
    let [suggestions, setSuggestions] = useState([])
    let [bluring, setBluring] = useState(true)
    let [tags, setTags] = useState([])
    let [alertErrorForm, setAlertErrorForm] = useState(false)
    let [limitSizeUploadMedia, setLimitSizeUploadMedia] = useState(null)
    let [limitSizeUploadPdf, setLimitSizeUploadPdf] = useState(null)

    useEffect(() => {
        setLimitSizeUploadMedia(appState.limitSizeUploadMedia)
    }, [appState.limitSizeUploadMedia])

    useEffect(() => {
        setLimitSizeUploadPdf(appState.limitSizeUploadPdf)
    }, [appState.limitSizeUploadPdf])

    const onChange = (e) => {
        if (e.target.type === 'checkbox')
            dispatch({ field: e.target.name, value: e.target.checked })
        else {
            dispatch({ field: e.target.name, value: e.target.value })
            if (e.target.name === "title") {
                setValidTitle(true)
            }
        }
    }

    const onAddressChange = (e) => {
        dispatch({ field: e.target.name, value: e.target.value })
        dispatch({ field: "lat", value: "" })
        dispatch({ field: "lng", value: "" })
        appDispatch({ type: "addMarker", value: { lat: null, lng: null, source: null } })
        setValidAddress(true)
        setBluring(true)
    }

    const checkValidInput = (e) => {
        if (e.target.name === "title") {
            setValidTitle(title.trim() !== '')
        } else if (e.target.name === "address") {
            if (bluring === true)
                setValidAddress(address.trim() !== '' && lat !== '' && lng !== '')
            setTimeout(() => {
                setSuggestions([])
            }, 500);
        }
    }

    useEffect(() => {

        if (firstUpdate.current && isEdit === true) {
            firstUpdate.current = false;
            return;
        }

        if (appState.newMarker.lat === null && appState.newMarker.lng === null && address.trim().length > 5) {
            const ourRequest = Axios.CancelToken.source()

            let data
            let response

            async function getSuggestions() {
                try {
                    if (process.env.NODE_ENV === 'development') {
                        const requestUrl = `https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=${process.env.REACT_APP_HERE_API_KEY}&query=${address}&maxresults=5`
                        response = await Axios.get(requestUrl)
                        data = response.data.suggestions
                    } else {
                        response = await locServices.getAutocompleteFromAddress(`?address=${address}`)
                        data = response.predictions.map(prediction => ({ label: prediction.description, locationId: prediction.place_id }))
                    }
                    setSuggestions(data)
                } catch (e) {
                    appDispatch({ type: "setError", value: e })
                }
            }

            let timeoutId = setTimeout(() => {
                getSuggestions()
            }, 500);

            return () => {
                ourRequest.cancel()
                clearTimeout(timeoutId);
            }
        } else {
            setSuggestions([])
        }
    }, [appState.newMarker, address, isEdit, appDispatch])

    useEffect(() => {
        if (appState.newMarker.lat !== null && appState.newMarker.lng !== null && appState.newMarker.source === "map") {
            const ourRequest = Axios.CancelToken.source()

            let data
            let response

            async function getAddress() {
                try {
                    if (process.env.NODE_ENV === 'development') {
                        const requestUrl = `https://reverse.geocoder.ls.hereapi.com/6.2/reversegeocode.json?apiKey=${process.env.REACT_APP_HERE_API_KEY}&mode=retrieveAddresses&prox=${appState.newMarker.lat},${appState.newMarker.lng}&maxresults=1`
                        response = await Axios.get(requestUrl)
                        data = response.data.Response.View[0].Result[0].Location
                        dispatch({ field: "address", value: data.Address.Label })
                        dispatch({ field: "lat", value: data.DisplayPosition.Latitude })
                        dispatch({ field: "lng", value: data.DisplayPosition.Longitude })
                    } else {
                        response = await locServices.getReverseGeocoding(`?latlng=${appState.newMarker.lat},${appState.newMarker.lng}`)
                        data = response.results[0]
                        dispatch({ field: "address", value: data.formatted_address })
                        dispatch({ field: "lat", value: data.geometry.location.lat })
                        dispatch({ field: "lng", value: data.geometry.location.lng })
                    }
                } catch (e) {
                    appDispatch({ type: "setError", value: e })
                }
            }

            getAddress()
            setValidAddress(true)
            return () => {
                ourRequest.cancel()
            }
        }
    }, [appState.newMarker, appDispatch])

    useEffect(() => {
        setTags(appState.tags)
    }, [appState.tags])

    useEffect(() => {
        if (title.trim() === "" || address.trim() === "" || lat.toString().trim() === "" || lng.toString().trim() === "" || datePub.toString() === "" || dateEndPrint.toString() === "") {
            setIsDisabled(true)
            setAlertErrorForm(true)
        } else {
            setIsDisabled(false)
            setAlertErrorForm(false)
        }
    }, [title, address, lat, lng, datePub, dateEndPrint])

    useEffect(() => {
        if (allData) {
            let infoData = {
                title: allData.title,
                description: checkIfNull(allData.description),
                address: allData.address,
                entreprise: checkIfNull(allData.entreprise),
                syndicat: checkIfNull(allData.syndicat),
                participant: checkIfNull(allData.nbParticipant),
                startDate: checkIfDateNull(allData.eventDate[0].dateTimeFrom),
                endDate: checkIfDateNull(allData.eventDate[0].dateTimeEnd),
                datePub: checkIfDateNull(allData.pubDate),
                dateEndPrint: checkIfDateNull(allData.dateEndPrint),
                lat: allData.locationGeoJSON.coordinates[1],
                lng: allData.locationGeoJSON.coordinates[0],
                image: allData.pictures && allData.pictures.path[2].basePath,
                pdf: allData.documents && (allData.documents.originalname ?? allData.documents.filename),
                tag: checkIfNull(allData.tags),
                isMine: allData.isMine,
                imageId: allData.pictures && allData.pictures._id,
                pdfId: allData.documents && allData.documents._id
            }
            dispatch({ field: "all", value: infoData })
        }
    }, [allData])

    const checkIfNull = (value) => {
        if (value === 0)
            return 0
        if (value)
            return value
        return ''
    }

    const checkIfDateNull = (date) => {
        if (date === null)
            return ''
        return new Date(date).toISOString().split('T')[0]
    }

    const safelyParseJSON = (object) => {
        try {
            return JSON.parse(object);
        } catch (ex) {
            return null;
        }
    }

    const submit = (e) => {
        e.preventDefault();
        setIsDisabled(true)
        setSubmitted(true)
        let { title, address, description, entreprise, syndicat, startDate, endDate, lat, lng, participant, pictureId, docId, datePub, dateEndPrint, isMine } = state
        let newInfo = {
            title, address, description, entreprise, syndicat, dateEndPrint, isMine,
            eventDate: [{ dateTimeFrom: startDate, dateTimeEnd: endDate }],
            locationGeoJSON: { type: "Point", coordinates: [lng, lat] },
            nbParticipant: participant,
            pubDate: datePub,
            origin: safelyParseJSON(localStorage.getItem('user')) ? safelyParseJSON(localStorage.getItem('user')).login : null,
            user: safelyParseJSON(localStorage.getItem('user')) ? safelyParseJSON(localStorage.getItem('user'))['_id'] : null,
        }

        if (pictureId !== '')
            newInfo.pictures = pictureId

        if (docId !== '')
            newInfo.documents = docId

        if (tag !== '')
            newInfo.tags = tag

        if (isEdit === true)
            infoServices.patchInfo(allData['_id'], newInfo)
                .then(() => {
                    appDispatch({ type: "infoSubmitted", value: 200 })
                })
                .catch((e) => {
                    appDispatch({ type: "infoSubmitted", value: 404 })
                    appDispatch({ type: "setError", value: e })
                })
                .finally(() => {
                    setIsDisabled(false)
                    setSubmitted(false)
                })
        else
            infoServices.createInfo(newInfo)
                .then(() => {
                    appDispatch({ type: "infoSubmitted", value: 200 })
                })
                .catch((e) => {
                    appDispatch({ type: "infoSubmitted", value: 404 })
                    appDispatch({ type: "setError", value: e })
                })
                .finally(() => {
                    setIsDisabled(false)
                    setSubmitted(false)
                })
    }

    const selectAddress = (e) => {
        let id = e.target.getAttribute("data-value")

        let response
        let data

        setValidAddress(true)
        async function getSelectedAddress() {
            try {
                if (process.env.NODE_ENV === 'development') {
                    const requestUrl = `https://geocoder.ls.hereapi.com/6.2/geocode.json?apiKey=${process.env.REACT_APP_HERE_API_KEY}&locationid=${id}`
                    response = await Axios.get(requestUrl)
                    data = response.data.Response.View[0].Result[0].Location
                    appDispatch({ type: "addMarker", value: { lat: data.DisplayPosition.Latitude, lng: data.DisplayPosition.Longitude, source: "list" } })
                    let center = { lat: data.DisplayPosition.Latitude, lng: data.DisplayPosition.Longitude }
                    dispatch({ field: "lat", value: center.lat })
                    dispatch({ field: "lng", value: center.lng })
                    dispatch({ field: "address", value: data.Address.Label })
                    appDispatch({ type: "infoAddressClicked", value: center })
                } else {
                    response = await locServices.getGeocodingFromPlaceId(`?place_id=${id}`)
                    data = response.results[0]
                    appDispatch({ type: "addMarker", value: { lat: data.geometry.location.lat, lng: data.geometry.location.lng, source: "list" } })
                    let center = { lat: data.geometry.location.lat, lng: data.geometry.location.lng }
                    dispatch({ field: "lat", value: center.lat })
                    dispatch({ field: "lng", value: center.lng })
                    dispatch({ field: "address", value: data.formatted_address })
                    appDispatch({ type: "infoAddressClicked", value: center })
                }
                setSuggestions([])

            } catch (e) {
                appDispatch({ type: "setError", value: e })
            }
        }
        getSelectedAddress()
    }

    const ignoreBlur = () => {
        setBluring(false)
    }

    return (
        <>
            {appState.newInfoStatus === null ?
                <FormContainer onSubmit={submit}>
                    <InfoTitle>{isEdit ? t('Modifier Info') : t('Ajouter Info')}</InfoTitle>
                    <CreateAlert color="danger" isOpen={alertErrorForm}>{t('form_on_error')}</CreateAlert>
                    <Info.InputContainer element="title" label="Titre" type="text" placeholder="Titre Placeholder" value={title} valid={validTitle} required onChange={onChange} onBlur={checkValidInput} asterix={true} />
                    <InputContainer>
                        <Label htmlFor="description">{t('Description')}</Label>
                        <InfoTextArea id="description" name="description" placeholder={t('Description Placeholder')} value={description} onChange={onChange} />
                    </InputContainer>
                    <Info.InputContainer
                        element="address"
                        label="Adresse"
                        type="text"
                        placeholder="Adresse Placeholder"
                        value={address} valid={validAddress}
                        required
                        onChange={onAddressChange}
                        onBlur={checkValidInput}
                        list={suggestions}
                        autoComplete="off"
                        selectAddress={selectAddress}
                        ignoreBlur={ignoreBlur}
                        asterix={true}
                    />
                    <Info.InputContainer element="entreprise" label="Entreprise" type="text" value={entreprise} onChange={onChange} />
                    <Info.SelectContainer element="tag" label="Mots Cle" array={tags} value={tag} onChange={onChange} />
                    <Info.InputContainer element="syndicat" label="Syndicat" type="text" value={syndicat} onChange={onChange} />
                    <Info.InputContainer element="participant" label="Participant" type="number" min="0" value={participant} onChange={onChange} />
                    <Info.InputContainer element="startDate" label="Date Debut" type="date" placeholder="aaaa-mm-jj" value={startDate} onChange={onChange} max={endDate} />
                    <Info.InputContainer element="endDate" label="Date Fin" type="date" placeholder="aaaa-mm-jj" value={endDate} onChange={onChange} min={startDate} />
                    <Info.InputContainer element="datePub" label="Debut Pub" type="date" placeholder="aaaa-mm-jj" value={datePub || ''} onChange={onChange} max={dateEndPrint} asterix={true} required />
                    <Info.InputContainer element="dateEndPrint" label="Fin Pub" type="date" placeholder="aaaa-mm-jj" value={dateEndPrint} onChange={onChange} min={datePub} asterix={true} required />
                    <Info.ToggleSwitch element="isMine" label="Info CGT" value={isMine} onChange={onChange} />
                    <Info.FileUploader element="media" label="Media" limit={limitSizeUploadMedia} accept="image/jpeg, image/jpg, image/png" dispatch={dispatch} image={image} imageId={imageId}/>
                    <Info.FileUploader element="pdf" label="Piece Jointe" limit={limitSizeUploadPdf} accept="application/pdf" dispatch={dispatch} pdf={pdf} pdfId={pdfId} />
                    <Info.Button isDisabled={isDisabled} type="submit" submitted={submitted} isEdit={isEdit} />
                </FormContainer>
                :
                <Info.AfterSubmit isEdit={isEdit} />

            }
        </>
    )
};

Info.LoggedOut = function InfoLoggedOut() {

    const { t } = useTranslation();

    return (
        <MessageContainer>
            <InfoTitle>{t('Deconnecte')}</InfoTitle>
            <MessageText>{t('Info Deconnecte')}</MessageText>
            <SigninLink to={ROUTES.SIGNIN}>{t('Connecter ici')}</SigninLink>
        </MessageContainer>
    )
};

Info.AfterSubmit = function InfoAfterSubmit({ isEdit }) {

    const { t } = useTranslation();
    const appState = useContext(StateContext)

    let error = appState.newInfoStatus === 200 ? false : true;

    return (
        <MessageContainer>
            <InfoTitle>{error ? t('Erreur Info') : isEdit ? t('Info Modifiee') : t('Info Ajoutee')}</InfoTitle>
            <Img src={error ? errorIcon : successIcon} />
            <MessageText addMargin={true}>{error ? t('Erreur Info Text') : isEdit ? t('Info Modifiee Text') : t('Info Ajoutee Text')}</MessageText>
        </MessageContainer>
    )
}

Info.Button = function InfoButton({ isDisabled, submitted, isEdit, ...restProps }) {

    const { t } = useTranslation();

    return (
        <AddButton disabled={isDisabled} {...restProps}>
            <Spinner submitted={submitted} />
            <Img src={plusIcon} />
            {isEdit ? t('Bouton Valider Edit') : t('Bouton Valider')}
        </AddButton>
    )
};

Info.InputContainer = function InfoInputContainer({ element, label, type, placeholder, valid, list, selectAddress, ignoreBlur, asterix, ...restProps }) {

    const { t } = useTranslation();

    return (
        <InputContainer>
            <Label htmlFor={element}>{t(label)}{asterix ? " *" : ""}</Label>
            <InfoInput type={type} id={element} name={element} placeholder={t(placeholder)} valid={valid} {...restProps} />
            {(element === "title" || element === "address") &&
                <InputFeedback valid={valid}>{element === "title" ? t('Title Invalide') : t('Adresse Invalide')}</InputFeedback>
            }
            {element === "address" &&
                <SuggestionsList>{list.map((item) => <li key={item.locationId} data-value={item.locationId} onMouseDown={ignoreBlur} onClick={selectAddress}>{item.label}</li>)}</SuggestionsList>}
        </InputContainer>
    )
};

Info.FileUploader = function InfoFileUploader({ element, label, limit, dispatch, image, pdf, imageId, pdfId, ...restProps }) {

    const { t } = useTranslation();
    const appDispatch = useContext(DispatchContext)

    const [fileLabel, setFileLabel] = useState(t("Aucun fichier"))
    const [isDisabled, setIsDisabled] = useState(false)
    const [thumbnail, setThumbnail] = useState("")
    const [enableImageButton, setEnableImageButton] = useState("")
    const [enablePdfButton, setEnablePdfButton] = useState("")

    useEffect(() => {
        if (image && image !== "") {
            setThumbnail(`${process.env.REACT_APP_PICTURE_STORAGE_URL}/${image}`)
            setFileLabel("")
        }
    }, [image])

    useEffect(() => {
        if (pdf && pdf !== "") {
            setFileLabel(pdf)
        }
    }, [pdf])

    useEffect(() => {
        if (imageId && imageId !== "") {
            setEnableImageButton(true)
        }
    }, [imageId])

    useEffect(() => {
        if (pdfId && pdfId !== "") {
            setEnablePdfButton(true)
        }
    }, [pdfId])

    const fileConvertSize = (aSize) => {
        aSize = Math.abs(parseInt(aSize, 10));
        const def = [[1, 'octets'], [1024, 'ko'], [1024*1024, 'Mo'], [1024*1024*1024, 'Go'], [1024*1024*1024*1024, 'To']];
        for(let i=0; i<def.length; i++){
            if(aSize<def[i][0]) return (aSize/def[i-1][0]).toFixed(0)+' '+def[i-1][1];
        }
    }
    limit = limit ? fileConvertSize(limit) : ''
    const labelLimit = limit ? t('info_file_size', {size: limit}) : ''

    const fileUpload = (e) => {
        appDispatch({ type: "setError", value: null })
        if (e.target.value === "") {
            setFileLabel(t("Aucun Fichier"))
            setThumbnail("")
            if (element === "media")
                dispatch({ field: "pictureId", value: '' })
            else if (element === "pdf")
                dispatch({ field: "docId", value: '' })
        } else {
            let file = e.target.files[0]
            const formData = new FormData();
            if (element === "media") {
                formData.append('pictures', file);
                uploadImage(formData)
            } else if (element === "pdf") {
                formData.append('documents', file);
                uploadPDF(formData)
            }
        }
    }

    const uploadImage = (data) => {
        setIsDisabled(true)
        infoServices.uploadImage(data)
            .then((res) => {
                let thumb = res.files[0].path[2].basePath
                setThumbnail(`${process.env.REACT_APP_PICTURE_STORAGE_URL}/${thumb}`)
                setFileLabel("")
                dispatch({ field: "pictureId", value: res.files[0]['_id'] })
            })
            .catch((e) => {
                appDispatch({ type: "setError", value: e })
            })
            .finally(() => {
                setIsDisabled(false)
            })
    }

    const uploadPDF = (data) => {
        setIsDisabled(true)
        infoServices.uploadPDF(data)
            .then((res) => {
                let currentFileName = res.files[0].originalname ?? res.files[0].filename
                setFileLabel(currentFileName)
                dispatch({ field: "docId", value: res.files[0]['_id'] })
            })
            .catch((e) => {
                appDispatch({ type: "setError", value: e })
            })
            .finally(() => {
                setIsDisabled(false)
            })
    }

    const deleteFile = async (fileType) => {

        if(fileType === 'media') {
            await infoServices.deleteImage(imageId);
            setThumbnail('');
            setFileLabel(t("Aucun fichier"));
            setEnableImageButton(false);
        }
        
        if(fileType === 'pdf') {
            await infoServices.deletePdf(pdfId);
            setThumbnail('');
            setFileLabel(t("Aucun fichier"));
            setEnablePdfButton(false);
        }
    }

    return (
        <InputContainer>
            <Label htmlFor={element}>{t(label) + labelLimit}</Label>
            {thumbnail !== "" && <Img src={thumbnail} />}
            <InputFile type="file" name={element} id={element} onChange={fileUpload} fileLabel={fileLabel} {...restProps} isDisabled={isDisabled} />
            <label htmlFor={element} ><span>{t("Parcourir")}</span></label>
            {enableImageButton && <DeleteButton class="delete-image" type="button" onClick={() => deleteFile(element)}> {t("delete")}</DeleteButton>}
            {enablePdfButton && <DeleteButton class="delete-image" type="button" onClick={() => deleteFile(element)}> {t("delete")}</DeleteButton>}
        </InputContainer>
    )
};

Info.SelectContainer = function InfoSelectContainer({ element, label, array, ...restProps }) {

    const { t } = useTranslation();

    return (
        <InputContainer>
            <Label htmlFor={element}>{t(label)}</Label>
            <CreateSelect name={element} id={element} {...restProps}>
                <option value=''></option>
                {array && array.map(option => (
                    <option key={option.key} value={option.key}>
                        {option.translate.fr}
                    </option>
                ))}
            </CreateSelect>
        </InputContainer>
    )
}

Info.ToggleSwitch = function InfoToggleSwitch({ element, label, value, ...restProps }) {

    const { t } = useTranslation();

    return (
        <InputContainer>
            <Label htmlFor={element}>{t(label)}</Label>
            <Switch htmlFor={element}>
                <input type="checkbox" checked={value} id={element} name={element} {...restProps} />
                <Slider className="slider" />
            </Switch>
        </InputContainer>
    )
}
