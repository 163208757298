import React from 'react';
import { render } from 'react-dom';
import BootstrapProvider from '@bootstrap-styled/provider';
import App from './app';
import './index.css';
import * as serviceWorker from './serviceWorker';
import './i18n';
import { theme } from './constants/theme';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { options } from './constants/matomo';
const instance = createInstance(options);

render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
      <BootstrapProvider theme={theme}>
        <App />
      </BootstrapProvider>
    </MatomoProvider>
  </React.StrictMode>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
