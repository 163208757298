import React from 'react';
import { useLocation } from "react-router-dom";
import { PageContainer, MessageBox, MessageAlert, PageTitle } from './styles';
import { useTranslation } from 'react-i18next';

import logo from '../../assets/logos/cgt-logo-rouge.svg';
import { Img } from '@bootstrap-styled/v4';

const queryString = require('query-string');

export default function Message({ children }) {
    const { t } = useTranslation();


    return (
        <MessageBox>
            <PageTitle>
                <Img src={logo} />
                <h1>{t('Admin')}</h1>
            </PageTitle>
            {children}
        </MessageBox>
    )
}

Message.Container = function MessageContainer({ children }) {

    return (
        <PageContainer>
            { children }
        </PageContainer>
    )
};

Message.BoxAlert = function MessageBoxAlert() {

    const { t } = useTranslation()
    let msg
    let location = useLocation();
    const parsed = queryString.parse(location.search)
    if(parsed.msg)
        msg = parsed.msg
    return (
        <MessageAlert color="success" uncontrolled> {t(msg)}</MessageAlert>
    )
};

