export const theme = {
    '$body-bg': 'black',
    '$body-color': 'white',
    colors: {
        main: "#F03E3E",
        title: "#343A40",
        content: "#868E96",
        input: "#F8F9FA",
        mainHover: "#C92A2A",
        dot: "#74B816",
        status: "#ADB5BD",
        suggest: "#404040",
        suggestHover: "#5b5b5b",
        defaultImage: "#F1F3F5",
        whiteBg: "#fff"
    },
    '$alert-danger-bg': "#feecec",
    '$alert-danger-text': "#F03E3E",
};