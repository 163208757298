import axios from 'axios';

const instance = axios.create({
    withCredentials: true
})

export const createInfo = async (body) => {
    let config = {
        headers: {
            'x-xsrf-token': JSON.parse(localStorage.getItem('xsrfToken'))
        }
    }
    try {
        const response = await instance.post(`${process.env.REACT_APP_BACK_URL}/infos`, body, config);
        return response.data
    } catch (error) {
        throw error;
    }
};

export const getInfo = async (conditions = '') => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/infos${conditions}`);
        return response.data
    } catch (error) {
        throw error;
    }
};

export const patchInfo = async (id, body) => {
    let config = {
        headers: {
            'x-xsrf-token': JSON.parse(localStorage.getItem('xsrfToken'))
        }
    }
    try {
        const response = await instance.patch(`${process.env.REACT_APP_BACK_URL}/infos/${id}`, body, config);
        return response.data
    } catch (error) {
        throw error;
    }
};

export const uploadImage = async (body) => {
    let config = {
        headers: {
            'x-xsrf-token': JSON.parse(localStorage.getItem('xsrfToken'))
        }
    }
    try {
        const response = await instance.post(`${process.env.REACT_APP_BACK_URL}/images`, body, config);
        return response.data
    } catch (error) {
        throw error;
    }
};

export const deleteImage = async (id) => {
    let config = {
        headers: {
            'x-xsrf-token': JSON.parse(localStorage.getItem('xsrfToken'))
        }
    }    
    try {
        const response = await instance.delete(`${process.env.REACT_APP_BACK_URL}/images/${id}`, config);
        return response.data
    } catch (error) {
        throw error;
    }
};

export const deletePdf = async (id) => {
    let config = {
        headers: {
            'x-xsrf-token': JSON.parse(localStorage.getItem('xsrfToken'))
        }
    }    
    try {
        const response = await instance.delete(`${process.env.REACT_APP_BACK_URL}/pdf/${id}`, config);
        return response.data
    } catch (error) {
        throw error;
    }
};

export const uploadPDF = async (body) => {
    let config = {
        headers: {
            'x-xsrf-token': JSON.parse(localStorage.getItem('xsrfToken'))
        }
    }
    try {
        const response = await instance.post(`${process.env.REACT_APP_BACK_URL}/docs`, body, config);
        return response.data
    } catch (error) {
        throw error;
    }
};

export const getInfoSchema = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/schemas/infos`);
        return response.data
    } catch (error) {
        throw error;
    }
};