import styled, { css } from 'styled-components'
import { Navbar, Container, NavbarToggler, Nav, Input, Img, Form, Collapse, NavLink } from '@bootstrap-styled/v4';
import breakpoint from '../../../constants/breakpoints'

export const HeaderNavbar = styled(Navbar)`
    
    background-color: ${props => props.theme.colors.main};

    && {
        padding: 2rem 2.5rem;
        @media ${breakpoint.device.md} {
            padding: 1rem;
        } 
    }
    .navbar-brand{
        transition: opacity .3s;
    }
    &:hover{
        #back-to-site {
            display: block;
        }
        .navbar-brand{
            opacity: 0;
            @media ${breakpoint.device.md} {
                opacity: 1;
            }
        }
    }
`

export const HeaderContainer = styled(Container)`
    
    padding: 0;
    max-width: none;

    &&& {
        @media ${breakpoint.device.lg} {
            display: block;
        }
    }
`
export const HeaderNavbarLink = styled.div`
   

    && {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }
    #back-to-site {
        width: 128px;
        height: 128px;
        display: none;
        transition: opacity .3s;
        @media ${breakpoint.device.md} {
            display: none !important;
        } 
    }
    #back-to-site:before {
        border-style: solid;
        border-width: 128px 128px 0 0;
        border-color: #fff transparent transparent transparent;
        content: '';
        position: absolute;
    }
    .inner-wrapper {
        z-index: 2;
        position: relative;
        padding: 16px 0 0 16px;
        display: flex;
        align-items: center;
    }
    .arrow-left {
        margin-right: 8px;
    }
`

export const HeaderNavbarToggler = styled(NavbarToggler)`
    height: 100%;
    position: relative;
    outline: none;
    cursor: pointer;
    &&& {
        border: 2px solid ${props => props.theme.colors.mainHover};
        ${props => props.isopen === "true" && css`
            background-color: ${props => props.theme.colors.mainHover};
        `}
    }
    &&& {
        @media ${breakpoint.device.lg} {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: .25rem 2rem; 
        }
    }
    &:hover {
        background-color: ${props => props.theme.colors.mainHover};
        .onlineDot {
            border-color: ${props => props.theme.colors.mainHover};
        }
    }
    .loginIcon {
        width: 24px;
        height: 24px;
    }
`
export const NavbarBrandContainer = styled.div`
    display: grid;
    gap: 1.5rem;
    grid-template-columns: auto 135px;
    align-items: center;
    @media ${breakpoint.device.md} {
        display: flex;
    }
`

export const HeaderLogo = styled(Img)`
    @media ${breakpoint.device.md} {
        max-height: 40px;
    }
`

export const GridContainer = styled.div`  
    display: grid;
    grid-template-columns: auto 2fr 2fr 1fr;
    gap: 2.5rem;
    @media ${breakpoint.device.md} {
        grid-template-columns: auto 1fr auto;
        gap: 1rem;
    }
`

export const HeaderNav = styled(Nav)`
    padding: 1rem 0;
    text-align: left;
    &&& {
        @media ${breakpoint.device.lg} {
            flex-direction: column;
        }
    }
    li {
        padding: 0 2rem;
        cursor: pointer;
        &:hover {
            background-color: ${props => props.theme.colors.input};
        }
        font-family: 'AvenirNext';
        font-size: 1rem;
        line-height: 1.375rem;
        white-space: nowrap;
    }
`

export const HeaderNavLink = styled(NavLink)`
    &&&& {
        color: ${props => props.theme.colors.title};
        text-decoration: none;
        padding: .5rem;
        display: block;
    }
`

export const HeaderInput = styled(Input)`
    && {
        padding: 1rem;
        padding-left: 4.5rem;
        font-family: 'AvenirNext';
        line-height: 1.5rem;
        font-size: 1.125rem;
        @media ${breakpoint.device.md} {
            padding: 0.5rem 1rem;
            padding-left: 2.5rem;
            /* padding-right: 1.5rem; */
            font-size: 0.875rem;
            ${props => props.fullscreen === "true" && props.isoverlay === "true" && css`
                margin-bottom: 1rem;
            `}
        }
    }
    &&& {
        color: ${props => props.theme.colors.content};
        @media ${breakpoint.device.sm} {
            width: 100%;
            height: 100%;
        }
    }
`

export const InputImage = styled(Img)`
    position: absolute;
    left: 2rem;
    @media ${breakpoint.device.md} {
        left: 1rem;
        width: 1rem;
        height: 1rem;
        top: 0.8rem
    }
`

const HideOnMobile = `
    @media ${breakpoint.device.md} {
        display: none;
    }
`

export const Signature = styled.span`
    font-size: 1.5rem;
    line-height: 2rem;
    font-family: 'AvenirNextBold';
    ${HideOnMobile};
`

export const NavBarTitle = styled.span`
    color: white;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-family: 'AvenirNextBold';
    margin: 0 0.5rem;
    ${HideOnMobile};
`

export const HideForm = styled(Form)`
    &&{
        @media ${breakpoint.device.md} {
            display: none;
            ${props => props.fullscreen === "true" && props.isoverlay === "true" && css`
                display: block;
            `}
            ${props => props.show === "true" && css`
                display: block;
            `}
        }
    }   
`

export const HideImage = styled(Img)`
    &&{
        ${HideOnMobile};
    }   
`

export const HeaderCollapse = styled(Collapse)`
    && {
        position: absolute;
    }
    top: calc(100% + 1rem);
    width: calc(100% + 4px);
    left: 0;
    background-color: white;
    border-radius: 4px;
    z-index: 1;
    cursor: pointer;
    box-shadow: 0px 4px 16px rgba(52, 58, 64, 0.16);
    ::after {
        content: " ";
        position: absolute;
        top: -16px;
        right: 2rem;
        margin-left: -5px;
        border-width: 8px;
        border-style: solid;
        border-color: transparent transparent white transparent;
    }
    @media ${breakpoint.device.md} {
        width: auto;
        right: 0;
        left: auto;
        ::after {
            right: 1rem;
        }
    }
`

export const OnlineDot = styled.div`
    position: absolute;
    width: 14px;
    height: 14px;
    background: ${props => props.theme.colors.dot};
    border: 2px solid ${props => props.theme.colors.main};
    border-radius: 50%;
    left: calc(2rem + 15px);
    top: calc(.25rem + 10px);
    ${props => props.isopen === "true" && css`
        border-color: ${props => props.theme.colors.mainHover};
    `}
    @media ${breakpoint.device.md} {
        left: auto;
        right: calc(.75rem - 4.5px);
        top: calc(.25rem - 2.5px);
    }
`
export const FullscreenOverlay = styled.div`
    position: fixed;
    padding: 1rem;
    height: 100vh;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    opacity: 0;
    background-color: ${props => props.theme.colors.main};
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all .5s;
    > img {
        margin-bottom: 2.5rem;
    }
    ${props => props.fullscreen === true && css`
        opacity: 1;
        z-index: 10;
    `}
`

export const SearchButton = styled.button`
    padding: 0.5rem;
    background: ${props => props.theme.colors.mainHover};;
    box-shadow: 0px 4px 16px rgba(52, 58, 64, 0.16);
    border-radius: 4px;
    font-family: 'AvenirNextBold';
    font-size: 0.875rem;
    line-height: 1.5rem;
    border: none;
    color: white;
    margin-top: 1.5rem;
`

export const CloseIcon = styled(Img)`
    position: absolute;
    top: 1rem;
    right: 1rem;
`

export const SuggestionsList = styled.ul`
    position: absolute;
    top: 4.5rem;
    padding: 1rem 0;
    left: 0;
    background-color: ${props => props.theme.colors.suggest};
    border-radius: 4px;
    z-index: 10;
    box-shadow: 0px 4px 16px rgba(52,58,64,0.16);
    list-style: none;
    @media ${breakpoint.device.md} {
        top: 3rem;
    }
    li {
        padding: 0.5rem 2rem;
        cursor: pointer;
        &:hover {
            background-color: ${props => props.theme.colors.suggestHover};
        }
        font-family: 'AvenirNext';
        font-size: 1rem;
        line-height: 1.375rem;
        @media ${breakpoint.device.md} {
            font-size: 0.875rem;
        }
    }
`