import styled, { css, keyframes } from 'styled-components';
import { Alert, Img } from '@bootstrap-styled/v4';
import { Link } from 'react-router-dom'
import breakpoint from '../../../constants/breakpoints'

export const PageContainer = styled.div`
    min-height: calc(100vh - 128px);
    background-color: ${props => props.theme.colors.input};
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'AvenirNextMedium';
    position: relative;
    @media ${breakpoint.device.md} {
        min-height: calc(100vh - 74px);
    }
`

export const SigninBox = styled.div`
    background-color: white;
    box-shadow: 0px 4px 16px rgba(52, 58, 64, 0.16);
    border-radius: 4px;
    position: relative;
    @media ${breakpoint.device.md} {
        width: calc(100% - 2rem)
    }
`

export const FieldsContainer = styled.div`
    padding: 2rem 2.5rem;
    padding-bottom: 0;
    @media ${breakpoint.device.md} {
        padding: 1.5rem;
    }
`

export const SigninTitle = styled.div`
    font-family: 'AvenirNextBold';
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${props => props.theme.colors.title};
    margin-bottom: 2rem;
    @media ${breakpoint.device.md} {
        font-size: 1.125rem;
        line-height: 1.5rem;
        margin-bottom: 1rem;
    }
`

export const Label = styled.label`
    display: block;
    color: ${props => props.theme.colors.content};
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 1rem;
    @media ${breakpoint.device.md} {
        font-size: 0.625rem;
        margin-bottom: 0.5rem;
    }
`

export const SigninInput = styled.input`
    background-color: ${props => props.theme.colors.input};
    border-radius: 4px;
    padding: 1.5rem;
    border: none;
    min-width: 488px;
    font-family: 'AvenirNext';
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: ${props => props.theme.colors.content};
    border: 1px solid transparent;
    ${props => props.valid === false && css`
        border: 1px solid ${props => props.theme.colors.main};
    `}
    @media ${breakpoint.device.md} {
        min-width: auto;
        width: 100%;
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
    }
`
const linkStyle = `
    font-size: 1rem;
    line-height: 1rem;
    cursor: pointer;
    font-family: 'AvenirNextMedium';
    text-decoration: none;
    :hover {
        text-decoration: underline;
    }
`

export const SigninLink = styled(Link)`
    ${linkStyle}
    color: ${props => props.theme.colors.main};
    @media ${breakpoint.device.md} {
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
`

export const ResetPass = styled.span`
    ${linkStyle}
    color: ${props => props.theme.colors.main};
    @media ${breakpoint.device.md} {
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
`

export const SigninButton = styled.button`
    background-color: ${props => props.theme.colors.main};
    font-family: 'AvenirNextBold';
    font-size: 1.125rem;
    line-height: 2rem;
    color: white;
    border: 0;
    padding: 1rem 0;
    cursor: pointer;
    border-radius: 0 0 4px 4px;
    width: calc(100% + 5rem);
    margin-left: -2.5rem;
    margin-top: 2rem;
    ${props => props.disabled === false && css`
        :hover {
            background-color: ${props => props.theme.colors.mainHover};
        }
    `}
    @media ${breakpoint.device.md} {
        font-size: 0.875rem;
        line-height: 1.5rem;
        padding: 0.5rem 0;
        margin: 0;
        margin-top: 1rem;
        width: 100%;
        border-radius: 4px;
    }
    ${props => props.disabled === true && css`
        opacity: 0.8;
        cursor: auto;
    `}
`

export const CreateAccount = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;

    color: ${props => props.theme.colors.title};
    position: absolute;
    bottom: -3rem;
    font-family: 'AvenirNext';
    width: 100%;
    text-align: center;
    @media ${breakpoint.device.md} {
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
`

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.span`
    position: absolute;
    display: none;
    left: 30%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: white;
    animation: ${rotate} 0.7s linear infinite;
    ${props => props.submitted === true && css`
        display: block;
    `}
    ${props => props.reset === true && css`
        left: 1rem;
    `}
    @media ${breakpoint.device.md} {
        left: 25%;
        width: 25px;
        height: 25px;
        ${props => props.reset === true && css`
            left: 2.5rem;
        `}
    }
`

export const EmailFeedback = styled.div`
    color: ${props => props.theme.colors.main};
    margin-top: 0.5rem;
    font-size: 0.75rem;
    display: none;
    ${props => props.valid === false && css`
        display: block;
    `}
`

export const InputContainer = styled.div`
    margin-bottom: 1.5rem;
    @media ${breakpoint.device.md} {
        margin-bottom: 1rem;
    }
`

export const SigninAlert = styled(Alert)`
    position: absolute;
    top: -25%;
    width: 100%;
    transform: translateY(-25%);
`

export const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(248, 249, 250, 0.95);
    opacity: 0;
    z-index: -1;
    transition: all .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    ${props => props.modal === true && css`
        opacity: 1;
        z-index: 10;
    `}
`

export const CloseButton = styled(Img)`
    position: absolute;
    top: 3rem;
    right: 5rem;
    width: 2rem;
    cursor: pointer;
    @media ${breakpoint.device.md} {
        top: 2rem;
        right: 2rem;
    }
`