import React, { useReducer, useContext } from 'react';
import { PageContainer, ProfileBox, HeaderContainer, UserIcon, HeaderText, HeaderStatus, HeaderSignout, FieldsContainer, Label, ProfileInput, InputContainer, ProfileButton, ProfileRemove, FieldsWrapper } from './styles/profile';
import { useTranslation } from 'react-i18next';
import DispatchContext from '../../context/DispatchContext'

export default function Profile({ children }) {
    return (
        <ProfileBox>{children}</ProfileBox>
    )
}

Profile.Container = function ProfileContainer({ children }) {

    return (
        <PageContainer>
            {children}
        </PageContainer>
    )
};

Profile.Fields = function ProfileFields() {

    const { t } = useTranslation();

    const initialState = {
        email: 'j.mineur@cgt78.fr',
        password: '88888888',
        firstName: 'Jean',
        lastName: 'Mineur',
        profession: 'Responsable R.H.',
        society: 'Renault France'
    }

    function reducer(state, { field, value }) {
        return {
            ...state,
            [field]: value
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const { email, password, firstName, lastName, profession, society } = state

    const onChange = (e) => {
        dispatch({ field: e.target.name, value: e.target.value })
    }

    return (
        <>
            <FieldsWrapper>
                <FieldsContainer>
                    <HeaderText>{t('Mes Infos')}</HeaderText>
                    <form>
                        <InputContainer>
                            <Label htmlFor="email">{t('Email')}</Label>
                            <ProfileInput type="email" id="email" name="email" value={email} onChange={onChange} />
                            {/* <EmailFeedback valid={validEmail}>{t('Email Invalide')}</EmailFeedback> */}
                        </InputContainer>
                        <InputContainer>
                            <Label htmlFor="password">{t('Password')}</Label>
                            <ProfileInput type="password" id="password" name="password" value={password} onChange={onChange} />
                            {/* <EmailFeedback valid={validPass}>{t('Password Invalide')}</EmailFeedback> */}
                        </InputContainer>
                        <InputContainer>
                            <Label htmlFor="firstName">{t('Nom')}</Label>
                            <ProfileInput type="text" id="firstName" name="firstName" value={firstName} onChange={onChange} />
                        </InputContainer>
                        <InputContainer>
                            <Label htmlFor="lastName">{t('Prenom')}</Label>
                            <ProfileInput type="text" id="lastName" name="lastName" value={lastName} onChange={onChange} />
                        </InputContainer>
                        <InputContainer>
                            <Label htmlFor="profession">{t('Profession')}</Label>
                            <ProfileInput type="text" id="profession" name="profession" value={profession} onChange={onChange} />
                        </InputContainer>
                        <InputContainer>
                            <Label htmlFor="society">{t('Societe')}</Label>
                            <ProfileInput type="text" id="society" name="society" value={society} onChange={onChange} />
                        </InputContainer>
                    </form>
                </FieldsContainer>
                <ProfileButton>{t('Bouton Profil')}</ProfileButton>
                {/* <Alert color="danger" isOpen={alertError} onClick={() => setAlertError(false)}></Alert>
            <Alert color="success" isOpen={alertSuccess} onClick={() => setAlertSuccess(false)}></Alert> */}
            </FieldsWrapper>
        </>
    )
};

Profile.Delete = function ProfileDelete() {

    const { t } = useTranslation();

    return (
        <ProfileRemove>{t('Supprimer Compte')}</ProfileRemove>
    )
};

Profile.Header = function ProfileHeader({ data }) {

    const { t } = useTranslation();
    const appDispatch = useContext(DispatchContext)

    const signOut = () => {
        appDispatch({ type: "logout" })
    }

    let translation = { pending: 'Compte Pending', validated: 'Compte Valide', blocked: 'Compte Blocked' }

    return (
        <HeaderContainer>
            <UserIcon>
                <div></div>
            </UserIcon>
            <HeaderText>{t('Profil Header')} {data && data.mail}</HeaderText>
            <HeaderStatus>{data && t(translation[data.status])}</HeaderStatus>
            <HeaderSignout onClick={signOut}>{t('Deconnecter')}</HeaderSignout>
        </HeaderContainer>
    )
};