import React from 'react';
import ResetPWD from '../components/resetpassword';

export function ResetPWDContainer() {

    return (
        <ResetPWD.Container>
            <ResetPWD>
                <ResetPWD.Fields/>
            </ResetPWD>
        </ResetPWD.Container>
    );
}