import * as infoServices from '../services/info_services';

export const getSchemas = (dispatch) => {
    infoServices.getInfoSchema()
        .then((data) => {
            const maxFileSizeMedia = data?.limitSizeUpload?.max_file_size || null
            const maxFileSizePdf = data?.limitSizeUpload?.max_doc_size || null
            dispatch({ type: "setTags", value: data.filters.tags })
            dispatch({ type: "setLimitSizeUploadMedia", value: maxFileSizeMedia})
            dispatch({ type: "setLimitSizeUploadPdf", value: maxFileSizePdf})
        })
        .catch((e) => {
            dispatch({ type: "setError", value: e })
        })
}