import React, { useEffect, useState, useContext } from 'react';
import { Profile } from '../components';
import * as userServices from '../services/user_services';
import DispatchContext from '../context/DispatchContext'

export function ProfileContainer() {

    let [profileData, setProfileData] = useState()
    const appDispatch = useContext(DispatchContext)

    useEffect(() => {
        userServices.getProfile()
            .then((data) => {
                setProfileData(data.user)
            })
            .catch((e) => {
                appDispatch({ type: "setError", value: e })
            })
    }, [appDispatch])

    return (
        <Profile.Container>
            <Profile>
                <Profile.Header data={profileData} />
                {/* <Profile.Fields />
                <Profile.Delete /> */}
            </Profile>
        </Profile.Container>
    );
}