import styled from 'styled-components';
import { Alert } from '@bootstrap-styled/v4';
import breakpoint from '../../constants/breakpoints';

export const PageContainer = styled.div`
    min-height: 100vh;
    background-color: ${props => props.theme.colors.input};
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'AvenirNextMedium';
    position:relative;
`

export const MessageBox = styled.div`
    background-color: white;
    box-shadow: 0px 4px 16px rgba(52, 58, 64, 0.16);
    border-radius: 4px;
    position: relative;
    @media ${breakpoint.device.md} {
        width: calc(100% - 2rem)
    }
`

export const MessageAlert = styled(Alert)`
    position: absolute;
    width: 50%;
`

export const PageTitle = styled.div`
    color: ${props => props.theme.colors.title};
    position: absolute;
    top: -7.5rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    h1 {
        margin-left: 1.5rem;
        position: relative;
        top: 0.25rem;
    }
`