import React, { useState, useContext, useRef, useEffect } from 'react';
import { ListScroll, ListWrapper, ListElement, Address, ListTitle, ListDetails, Toggle, AddButton, InfoHeader, DescriptionContainer, Description, PubDate, Return, ScrollContainer, EditButton, FilesContainer, EmptyList } from './styles/list';
import { Img } from '@bootstrap-styled/v4';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ChevronLeft } from '../../assets/svg/chevron-left-icon.svg';
import { ReactComponent as Marker } from '../../assets/svg/marker-grey-icon.svg'
import logoGrey from '../../assets/svg/logo-grey.svg'
import pdfIcon from '../../assets/svg/pdf-icon.svg'
import DispatchContext from '../../context/DispatchContext'
import { useTranslation } from 'react-i18next';
import * as infoServices from '../../services/info_services'
import { FacebookShareButton, TwitterShareButton } from "react-share";
import twitterIcon from '../../assets/svg/twitter.svg'
import facebookIcon from '../../assets/svg/facebook.svg'
import linkedinIcon from '../../assets/svg/linkedin.svg'

export default function List({ children, showInfo, listRef }) {

    const [isHidden, setIsHidden] = useState(false)

    return (
        <ListWrapper isHidden={isHidden} showInfo={showInfo} ref={listRef}>
            {children}
            <Toggle onClick={() => setIsHidden(!isHidden)} isHidden={isHidden}>
                <ChevronLeft />
            </Toggle>
        </ListWrapper>
    )
}

List.Container = function ListContainer({ infos, conditions, scrollHeight }) {

    const [postList, setPostList] = useState(infos);
    const [page, setPage] = useState(0);
    const [fetchMore, setFetchMore] = useState(true)
    // add loader refrence
    const loader = useRef(null);
    const updatesRef = useRef(0)
    const pageRef = useRef(0);
    const scrollRef = useRef()
    const { t } = useTranslation();

    useEffect(() => {
        var options = {
            root: null,
            rootMargin: "20px",
            threshold: 0.5
        };
        // initialize IntersectionObserver
        // and attaching to Load More div
        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
            observer.observe(loader.current)
        }

    }, []);

    useEffect(() => {
        setPostList(infos)
        setFetchMore(true)
        pageRef.current = page
        scrollRef.current.scrollTop = 0
        //eslint-disable-next-line
    }, [infos])

    useEffect(() => {
        if (page > 1 && pageRef.current !== page && fetchMore === true && postList.length > 0) {
            pageRef.current = page
            let newConditions = conditions
            newConditions += `&skip=${postList.length}`
            infoServices.getInfo(newConditions)
                .then((res) => {
                    if (res.infos.length === 0)
                        setFetchMore(false)
                    else
                        setPostList(currentList => currentList.concat(res.infos))
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    }, [page, conditions, fetchMore, postList])

    useEffect(() => {
        updatesRef.current++
    }, [postList])

    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting) {
            setPage((page) => page + 1)
        }
    }
    return (
        <ScrollContainer style={{height: scrollHeight}}>
            <ListScroll ref={scrollRef}>
                {postList.length > 0 ?
                    postList.map(marker =>
                        <List.Marker
                            key={marker['_id']}
                            title={marker.title}
                            address={marker.address}
                            pubDate={marker.pubDate}
                            author={marker.origin}
                            allData={marker}
                            image={marker.pictures && marker.pictures.path[1].basePath}
                        />
                    )
                    :
                    updatesRef.current > 1 && <EmptyList>{t("Pas de Resultats")}</EmptyList>
                }
                <div className="loading" style={{ height: "1px" }} ref={loader}>
                </div>
            </ListScroll>
        </ScrollContainer>
    )
};

List.Marker = function ListMarker({ title, address, pubDate, author, allData, image }) {

    let history = useHistory();
    const appDispatch = useContext(DispatchContext)

    const elementClicked = () => {
        history.push(`/${allData.slug}-${allData['_id']}`);
        appDispatch({ type: "listClicked", value: allData })
    }

    const elementHovered = () => {
        appDispatch({ type: "listHovered", value: allData })
    }

    const elementUnhovered = () => {
        appDispatch({ type: "resetList", value: null })
    }

    const editInfo = (e) => {
        e.stopPropagation()
        appDispatch({ type: "editInfo", value: { status: true, data: allData } })
    }

    const safelyParseJSON = (object) => {
        try {
            return JSON.parse(object);
        } catch (ex) {
            return null;
        }
    }

    const { t } = useTranslation();

    const user = safelyParseJSON(localStorage.getItem('user'));
    const login = user && user['login'] ? user['login'] : '';

    return (
        <ListElement onClick={elementClicked} onMouseOver={elementHovered} onMouseLeave={elementUnhovered}>
            <div>
                <Address className="address">
                    <Marker />
                    {address}
                </Address>
                <ListTitle className="title">{title}</ListTitle>
                <div className="d-flex justify-content-between flex-wrap align-items-center">
                    <ListDetails>{t('Publie le')} <strong>{pubDate.substring(0, 10)}</strong> {t('par')} <strong><u>{author}</u></strong> </ListDetails>
                    {login === author &&
                        <EditButton onClick={editInfo}>{t('Modifier')}</EditButton>
                    }
                </div>
            </div>
            {image
                ?
                <Img src={`${process.env.REACT_APP_PICTURE_STORAGE_URL}/${image}`} onError={(e) => { e.target.onerror = null; e.target.src = logoGrey; e.target.className = 'error-image' }} />
                :
                <Img className="error-image" src={logoGrey} />
            }
        </ListElement>
    )
};

List.Button = function ListButton({ plusIcon, buttonRef }) {

    const { t } = useTranslation();

    const appDispatch = useContext(DispatchContext)

    const addInfo = () => {
        appDispatch({ type: "addInfo", value: true })
    }

    return (
        <AddButton onClick={addInfo} ref={buttonRef}>
            <Img src={plusIcon} />
            {t('Bouton Ajouter')}
        </AddButton>
    )
};

List.Info = function ListInfo({ active, scrollHeight }) {

    let history = useHistory();
    const appDispatch = useContext(DispatchContext)

    const backToResults = () => {
        appDispatch({ type: "resetMap", value: null })
        history.push(`/`);
    }

    const { t } = useTranslation();

    let image = active.pictures ? `${process.env.REACT_APP_PICTURE_STORAGE_URL}/${active.pictures.path[0].basePath}` : ''
    let pdfOriginalFilename = active.documents?.originalname ?? active.documents?.filename

    return (
        <ListScroll infoOpen={true} style={{height: scrollHeight}}>
            <InfoHeader image={image}>
                <div className="d-flex justify-content-between align-items-start">
                    <Return onClick={backToResults}><ChevronLeft />{t('Retour Resultats')}</Return>
                    <List.SocialShare shareUrl={`${process.env.REACT_APP_FRONT_URL}/${active.slug}-${active['_id']}`} />
                </div>
                <div>
                    <Address color="white">
                        <Marker />
                        {active.address}
                    </Address>
                    <ListTitle color="white">{active.title}</ListTitle>
                </div>
            </InfoHeader>
            <DescriptionContainer>
                <Description>{active.description}</Description>
                {active.documents &&
                    <FilesContainer>
                        <h5>{t('Pieces Jointes')}</h5>
                        <a href={`${process.env.REACT_APP_FILE_STORAGE_URL}${active.documents.path}`} download target="_blank" rel="noopener noreferrer">
                            <div className="pdf">
                                <Img src={pdfIcon} />
                                {pdfOriginalFilename}
                            </div>
                        </a>
                    </FilesContainer>
                }
            </DescriptionContainer>
            <PubDate>{t('Publie le')} <strong>{active.pubDate.substring(0, 10)}</strong> {t('par')} <strong><u>{active.origin}</u></strong></PubDate>
        </ListScroll>
    )
}


List.SocialShare = function ListSocialShare({ shareUrl }) {
    return (
        <div className="socials">
            <FacebookShareButton url={shareUrl}>
                <Img src={facebookIcon} />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl}>
                <Img src={twitterIcon} />
            </TwitterShareButton>

            {/* not working */}

            {/* <LinkedinShareButton url={shareUrl}>
                <Img src={linkedinIcon} />
            </LinkedinShareButton> */}

            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/sharing/share-offsite/?url=https://mobilisations-en-france.cgt.fr/news/map">
                <Img src={linkedinIcon} />
            </a>
        </div>
    )
}