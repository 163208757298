import React from 'react';
import Message from '../components/message/message';

export function MessageContainer() {

    return (
        <Message.Container>
            <Message.BoxAlert></Message.BoxAlert>
        </Message.Container>
    );
}