import axios from 'axios';


export const getAutocompleteFromAddress = async (params) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/location/autocomplete/places${params}`);
        return response.data
    } catch (error) {
        throw error;
    }
};

export const getGeocoding = async (params) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/location/geocode${params}`);
        return response.data
    } catch (error) {
        throw error;
    }
};
export const getGeocodingFromPlaceId = async (params) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/location/geocode/placeId${params}`);
        return response.data
    } catch (error) {
        throw error;
    }
};

export const getReverseGeocoding = async (params) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/location/reversegeocode${params}`);
        return response.data
    } catch (error) {
        throw error;
    }
};