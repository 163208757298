import styled, { css } from 'styled-components';
import { Img } from '@bootstrap-styled/v4';
import markerAdd from '../../../assets/svg/marker-add.svg'
import markerNew from '../../../assets/svg/marker-new.svg'
import markerCluster from '../../../assets/clusters/m1.svg'

export const MapWrapper = styled.div`
    .gm-style:first-of-type > div:nth-child(1) {
        ${props => props.cursorAdd === true && css`
            cursor: url(${markerAdd}) 18 54, auto !important;
        `}
        ${props => props.cursorAdd === true && props.cursorNew === true && css`
            cursor:  url(${markerNew}) 18 54, auto !important;
        `}
    }
`

export const MarkerImg = styled(Img)`
    position: relative;
    z-index: 1;
    ${props => props.imgactive === "true" && css`
        z-index: 2;
    `}
    ${props => props.infoopen === "true" && css`
        pointer-events: none;
    `}
`
const handleClusterImg = pointCount => {
    switch (true) {
        case (pointCount >= 10 && pointCount < 20):
            return `
                width: 56px;
                height: 56px;
                font-size: 14px;
            `;
        case (pointCount >= 20 && pointCount < 50):
            return `
                width: 64px;
                height: 64px;
                font-size: 16px;
            `;
        case (pointCount >= 50  && pointCount < 80):
            return `
                width: 80px;
                height: 80px;
                font-size: 20px;
            `;
        case (pointCount >= 80):
            return `
                width: 88px;
                height: 88px;
                font-size: 24px;
            `;
        default:
            return `
                width: 48px;
                height: 48px;
                font-size: 12px;
            `;
    }
  };

export const ClusterMarkerStyled = styled.div`
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-image: url(${markerCluster});
    ${({ pointCount }) => handleClusterImg(pointCount)};
    z-index: 1;
`