import styled from 'styled-components';
import { Alert } from '@bootstrap-styled/v4';
import breakpoint from '../../../constants/breakpoints'

export const CustomAlert = styled(Alert)`
    position: absolute;
    top: 148px;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    @media ${breakpoint.device.md} {
        top: 134px;
        width: calc(100% - 2rem);
    } 
`