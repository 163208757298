import React, { useState, useEffect, useContext } from 'react';
import { PageContainer, ResetPWDBox, FieldsContainer, ResetPWDTitle, Label, ResetPWDInput, ResetPWDButton, Spinner, FieldFeedback, InputContainer, ResetPWDAlert, PageTitle } from './styles/resetpassword';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as authServices from '../../services/auth_services';
import DispatchContext from '../../context/DispatchContext'
import * as ROUTES from '../../constants/routes';
import logo from '../../assets/logos/cgt-logo-rouge.svg'
import { Img } from '@bootstrap-styled/v4';

export default function ResetPWD({ children }) {
    const { t } = useTranslation();
    return (
        <ResetPWDBox>
            <PageTitle>
                <Img src={logo} />
                <h1>{t('Carte')}</h1>
            </PageTitle>
            {children}
        </ResetPWDBox>
    )
}

ResetPWD.Container = function ResetPWDContainer({ children }) {
    return (
        <PageContainer>
            {children}
        </PageContainer>
    )
};

ResetPWD.Fields = function ResetPWDFields({ newAccount, reset }) {

    const { t } = useTranslation();
    const appDispatch = useContext(DispatchContext)
    let history = useHistory();

    let [password, setPassword] = useState("")
    let [validPass, setValidPass] = useState("")
    let [disabled, setDisabled] = useState(true)
    let [submitted, setSubmitted] = useState(false)
    let [alertSuccess, setAlertSuccess] = useState(false)


    function checkValidPass() {
        if (password.trim().length >= 8)
            setValidPass(true)
        else
            setValidPass(false)
    }

    function connect(e) {
        e.preventDefault();
        const tokenToCheck = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

        appDispatch({ type: "setError", value: null })
        setDisabled(true)
        setSubmitted(true)

        authServices.resetPassword(password, tokenToCheck)
            .then((res) => {
                onSuccess()
            })
            .catch((e) => {
                onError(e)
            })
    }

    const onSuccess = () => {
        setAlertSuccess(true)
        setSubmitted(false)
        setTimeout(() => {
            setAlertSuccess(false)
            history.push(ROUTES.HOME)
            setDisabled(false)
        }, 2000);
    }

    const onError = (e) => {
        appDispatch({ type: "setError", value: e })
        setDisabled(false)
        setSubmitted(false)
        setTimeout(() => {
            history.replace(ROUTES.HOME);
        }, 3000);
    }


    const handlePassChange = (e) => {
        setPassword(e.target.value)
        setValidPass(true)
    }


    useEffect(() => {
        //TODO double passwd fields and validate is equal
        let condition = password.trim().length >= 8

        if (condition)
            setDisabled(false)
        else
            setDisabled(true)
    }, [password])

    return (
        <>
            <FieldsContainer>
                <ResetPWDTitle>{t('Reset Password')}</ResetPWDTitle>
                <form onSubmit={connect}>
                    <InputContainer>
                        <Label htmlFor="password">{t('Password')} *</Label>
                        <ResetPWDInput type="password" id="password" name="password" value={password} valid={validPass} onChange={handlePassChange} placeholder={t('Password Placeholder')} {...({ onBlur: checkValidPass })} />
                        <FieldFeedback valid={validPass}>{t('Password Invalide')}</FieldFeedback>
                    </InputContainer>
                    <ResetPWDButton type="submit" disabled={disabled}><Spinner submitted={submitted} reset={reset} />{t('Reset Password')}</ResetPWDButton>
                </form>
            </FieldsContainer>
            <ResetPWDAlert color="success" isOpen={alertSuccess} onClick={() => setAlertSuccess(false)}> {t('reset_password_done')}</ResetPWDAlert>
        </>
    )
};
