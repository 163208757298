import styled from 'styled-components/macro';

export const Inner = styled.div`
    display: flex;
    align-items: center;
    flex-direction: ${({ direction}) => direction };
    jsutify-content: space-between;
    maw-witdh: 1100px;

    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;
export const Pane = styled.div`
    width: 50%;
`;
export const Title = styled.h2``;

export const SubTitle = styled.h3``;

export const Image = styled.image``;

export const Container = styled.div``;

export const Item = styled.div`
  display: flex;
  border-bottom: 8px solid #222;
  padding: 50px 5%;
  color: white;
  overflow: hidden;
`;