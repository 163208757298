import styled, { css, keyframes } from 'styled-components';
import { Alert } from '@bootstrap-styled/v4';
import breakpoint from '../../../constants/breakpoints';

export const PageContainer = styled.div`
    min-height: 100vh;
    background-color: ${props => props.theme.colors.input};
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'AvenirNextMedium';
    position:relative;
`

export const ResetPWDBox = styled.div`
    background-color: white;
    box-shadow: 0px 4px 16px rgba(52, 58, 64, 0.16);
    border-radius: 4px;
    position: relative;
    @media ${breakpoint.device.md} {
        width: calc(100% - 2rem)
    }
`

export const FieldsContainer = styled.div`
    padding: 2rem 2.5rem;
    padding-bottom: 0;
    @media ${breakpoint.device.md} {
        padding: 1.5rem;
    }
`

export const ResetPWDTitle = styled.div`
    font-family: 'AvenirNextBold';
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${props => props.theme.colors.title};
    margin-bottom: 2rem;
    @media ${breakpoint.device.md} {
        font-size: 1.125rem;
        line-height: 1.5rem;
        margin-bottom: 1rem;
    }
`

export const Label = styled.label`
    display: block;
    color: ${props => props.theme.colors.content};
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 1rem;
    @media ${breakpoint.device.md} {
        font-size: 0.625rem;
        margin-bottom: 0.5rem;
    }
`

export const ResetPWDInput = styled.input`
    background-color: ${props => props.theme.colors.input};
    border-radius: 4px;
    padding: 1.5rem;
    border: none;
    min-width: 488px;
    font-family: 'AvenirNext';
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: ${props => props.theme.colors.content};
    border: 1px solid transparent;
    ${props => props.valid === false && css`
        border: 1px solid ${props => props.theme.colors.main};
    `}
    @media ${breakpoint.device.md} {
        min-width: auto;
        width: 100%;
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
    }
`

export const ResetPWDButton = styled.button`
    background-color: ${props => props.theme.colors.main};
    font-family: 'AvenirNextBold';
    font-size: 1.125rem;
    line-height: 2rem;
    color: white;
    border: 0;
    padding: 1rem 0;
    cursor: pointer;
    border-radius: 0 0 4px 4px;
    width: calc(100% + 5rem);
    margin-left: -2.5rem;
    margin-top: 2rem;
    ${props => props.disabled === false && css`
        :hover {
            background-color: ${props => props.theme.colors.mainHover};
        }
    `}
    @media ${breakpoint.device.md} {
        font-size: 0.875rem;
        line-height: 1.5rem;
        padding: 0.5rem 0;
        margin: 0;
        margin-top: 1rem;
        width: 100%;
        border-radius: 4px;
    }
    ${props => props.disabled === true && css`
        opacity: 0.8;
        cursor: auto;
    `}
`

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.span`
    position: absolute;
    display: none;
    left: 30%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: white;
    animation: ${rotate} 0.7s linear infinite;
    ${props => props.submitted === true && css`
        display: block;
    `}
    ${props => props.reset === true && css`
        left: 1rem;
    `}
    @media ${breakpoint.device.md} {
        left: 25%;
        width: 25px;
        height: 25px;
        ${props => props.reset === true && css`
            left: 2.5rem;
        `}
    }
`

export const FieldFeedback = styled.div`
    color: ${props => props.theme.colors.main};
    margin-top: 0.5rem;
    font-size: 0.75rem;
    display: none;
    ${props => props.valid === false && css`
        display: block;
    `}
`

export const InputContainer = styled.div`
    margin-bottom: 1.5rem;
    @media ${breakpoint.device.md} {
        margin-bottom: 1rem;
    }
`

export const ResetPWDAlert = styled(Alert)`
    position: absolute;
    top: -25%;
    width: 100%;
    transform: translateY(-25%);
`

export const PageTitle = styled.div`
    color: ${props => props.theme.colors.title};
    position: absolute;
    top: -7.5rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    h1 {
        margin-left: 1.5rem;
        position: relative;
        top: 0.25rem;
    }
`