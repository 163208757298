import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom'
import breakpoint from '../../../constants/breakpoints'
import { Alert } from '@bootstrap-styled/v4';


export const CreateAlert = styled(Alert)`
    font-size:0.8em;
    text-align: center;
`
export const InfoWrapper = styled.div`
    position: absolute;
    top: 2.5rem;
    right: 3.5rem;
    height: calc(100% - 5rem);
    background-color: white;
    width: 40%;
    box-shadow: 0px 4px 16px rgba(52, 58, 64, 0.16);
    border-radius: 4px;
    transition: transform .4s;
    font-family: 'AvenirNextMedium';
    ${props => props.isHidden === true && css`
        transform: translateX(calc(100% + 0.5rem));
    `}
    @media ${breakpoint.device.md} {
        top: auto;
        bottom: 0;
        right: 1rem;
        height: 60%;
        width: calc(100% - 2rem);
        ${props => props.isHidden === true && css`
            transform: translateY(calc(100% - 2.25rem));
        `}
    }
`

export const Toggle = styled.div`
    background-color: ${props => props.theme.colors.input};
    border-radius: 4px 0px 0px 4px;
    width:38px;
    height:96px;
    position: absolute;
    top: 1rem;
    left: -38px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
        transform: scale(1.4);
        transition: transform .4s;
        ${props => props.isHidden === false && css`
            transform: scale(1.4)rotate(180deg);
        `}
        path {
            fill: ${props => props.theme.colors.content};
        }
    }
    @media ${breakpoint.device.md} {
        left: 50%;
        width: 24px;
        height: 72px;
        transform: rotate(90deg);
        border-radius: 4px 0px 0px 4px;
        top: -47px;
        svg {
            ${props => props.isHidden === false && css`
                transform: scale(1.4)rotate(180deg);
            `}
        }
    }
`

export const AddButton = styled.button`
    background-color: ${props => props.theme.colors.main};
    border-radius: 0px 0px 4px 4px;
    font-family: 'AvenirNextBold';
    font-size: 1.125rem;
    line-height: 2rem;
    color: white;
    border: 0;
    cursor: pointer;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 1rem 0;
    img {
        margin-right: 1rem;
    }
    ${props => props.disabled === false && css`
        :hover {
            background-color: ${props => props.theme.colors.mainHover};
        }
    `}
    @media ${breakpoint.device.md} {
        font-size: 0.875rem;
        line-height: 1.5rem;
        padding: 0.5rem 0;
        width: calc(100% - 3rem);
        left: 1.5rem;
        position: absolute;
        bottom: 1.5rem;
        border-radius: 4px;
        img {
            margin-right: 0.5rem;
            transform: scale(0.8)
        }
    }
    ${props => props.disabled === true && css`
        opacity: 0.8;
        cursor: auto;
    `}
`

export const Return = styled.div`
    font-family: 'AvenirNextMedium';
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 2rem;
    color: ${props => props.theme.colors.main};
    cursor: pointer;
    svg {
        margin-right: 1rem;
        path {
            fill: ${props => props.theme.colors.main};
        }
    }
    :hover {
        text-decoration: underline;
    }
    @media ${breakpoint.device.md} {
        margin-bottom: 2rem;
        font-size: 0.875rem;
    }
`

export const ScrollContainer = styled.div`
    padding: 2rem 2.5rem;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const InfoTitle = styled.h2`
    line-height: 2rem;
    color: ${props => props.theme.colors.title};
    margin-bottom: 1.5rem;
    @media ${breakpoint.device.md} {
        font-size: 1.125rem;
        line-height: 1.5rem;
    }
`

export const InputContainer = styled.div`
    margin-bottom: 1.5rem;
    position: relative;
`

export const Label = styled.label`
    display: block;
    color: ${props => props.theme.colors.content};
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 1rem;
    @media ${breakpoint.device.md} {
        font-size: 0.625rem;
    }
`

const inputStyle = css`
  background-color: ${props => props.theme.colors.input};
    border-radius: 4px;
    padding: 1.25rem 1.5rem;
    width: 100%;
    font-family: 'AvenirNext';
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: ${props => props.theme.colors.content};
    border: 1px solid transparent;
    ${props => props.valid === false && css`
        border: 1px solid ${props => props.theme.colors.main};
    `}
    @media ${breakpoint.device.md} {
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
    }
`

export const CreateSelect = styled.select`
    ${inputStyle}
`

export const InfoInput = styled.input`
    ${inputStyle}
    ${props => props.valid === false && css`
        border: 1px solid ${props => props.theme.colors.main};
    `}
`

export const InputFeedback = styled.div`
    color: ${props => props.theme.colors.main};
    margin-top: 0.5rem;
    font-size: 0.75rem;
    display: none;
    ${props => props.valid === false && css`
        display: block;
    `}
`

export const InfoTextArea = styled.textarea`
    ${inputStyle}
    border: none;
    resize: none;
    min-height: 177px;
    @media ${breakpoint.device.md} {
        min-height: 119px;
    }
`

export const FormContainer = styled.form`
    overflow: auto;
    margin-bottom: 2rem;
`

export const InputFile = styled.input`
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
    top: 0;
    + label {
        span, button {
            font-family: 'AvenirNext';
            font-size: 0.875rem;
            line-height: 1rem;
            color: white;
            background-color: ${props => props.theme.colors.content};
            border-radius: 4px;
            padding: 0.5rem 1rem;
            margin-left: 1.5rem;
            :hover {
                background-color: ${props => props.theme.colors.title};
            }
            @media ${breakpoint.device.md} {
                padding: 0.25rem 1rem;
                font-size: 0.75rem;
            }
            ${props => props.isDisabled === true && css`
                pointer-events: none;
                span {
                    opacity: 0.5;
                }
            `}
        }
        display: inline-block;
        cursor: pointer;
        :before {
            content: "";
            content: ${props => `"${props.fileLabel}"`};
            font-family: 'AvenirNext';
            font-size: 1rem;
            line-height: 2rem;
            color: ${props => props.theme.colors.title};
            @media ${breakpoint.device.md} {
                font-size: 0.875rem;
                line-height: 1.5rem;
            }
        }
    }
`

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.span`
    position: absolute;
    display: none;
    left: 2rem;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: white;
    animation: ${rotate} 0.7s linear infinite;
    ${props => props.submitted === true && css`
        display: block;
    `}
    @media ${breakpoint.device.md} {
        left: 1rem;
        width: 25px;
        height: 25px;
    }
`

export const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.title};
    height: 100%;
`

export const MessageText = styled.div`
    font-size: 1rem;
    line-height: 2rem;
    text-align: center;
    font-family: 'Metropolis';
    margin-bottom: 1rem;
    ${props => props.addMargin === true && css`
        margin-top: 1rem;
    `}
    @media ${breakpoint.device.md} {
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
`

export const SigninLink = styled(Link)`
    font-size: 1rem;
    line-height: 1rem;
    color: ${props => props.theme.colors.main};
    cursor: pointer;
    font-family: 'AvenirNextMedium';
    text-decoration: none;
    :hover {
        text-decoration: underline;
    }
    @media ${breakpoint.device.md} {
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
`

export const SuggestionsList = styled.ul`
    position: absolute;
    top: 6.5rem;
    padding: 1rem 0;
    left: 0;
    background-color: ${props => props.theme.colors.suggest};
    border-radius: 4px;
    z-index: 10;
    box-shadow: 0px 4px 16px rgba(52,58,64,0.16);
    @media ${breakpoint.device.md} {
        top: 5rem;
    }
    li {
        padding: 0.5rem 2rem;
        cursor: pointer;
        &:hover {
            background-color: ${props => props.theme.colors.suggestHover};
        }
        font-family: 'AvenirNext';
        font-size: 1rem;
        line-height: 1.375rem;
    }
`

export const Switch = styled.label`
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    @media ${breakpoint.device.md} {
        width: 48px;
        height: 27px;
    }
    input {
        opacity: 0;
        width: 0;
        height: 0;
        &:checked + .slider {
            background-color: ${props => props.theme.colors.title};
            :before {
                transform: translateX(26px);
                @media ${breakpoint.device.md} {
                    transform: translateX(20px);
                }
            }
        }
    }
`

export const Slider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => props.theme.colors.content};
    transition: .4s;
    border-radius: 100px;
    &:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        transition: .4s;
        border-radius: 50%;
        @media ${breakpoint.device.md} {
            height: 20px;
            width: 20px;
        }
    }
`
export const DeleteButton = styled.button`
    font-family: 'AvenirNext';
    font-size: 0.875rem;
    line-height: 1rem;
    color: white;
    border: 0;
    cursor: pointer;
    background-color: ${props => props.theme.colors.mainHover};
    border-radius: 4px;
    padding: 0.5rem 1rem;
    margin-left: 1.5rem;
    :hover {
        background-color: ${props => props.theme.colors.title};
    }
    @media ${breakpoint.device.md} {
        padding: 0.25rem 1rem;
        font-size: 0.75rem;
    }
    ${props => props.isDisabled === true && css`
        pointer-events: none;
        span {
            opacity: 0.5;
        }
    `}
`