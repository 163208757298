import React, { useState, useContext, useEffect, useRef } from 'react';
import { A, Form, NavbarBrand, NavItem, Img } from '@bootstrap-styled/v4';
import {
    HeaderNavbar, HeaderContainer, HeaderNavbarToggler, HeaderNavbarLink, HeaderLogo, GridContainer, HeaderNav, HeaderInput, InputImage, Signature, HideForm, HeaderCollapse,
    HideImage, NavBarTitle, HeaderNavLink, OnlineDot, NavbarBrandContainer, FullscreenOverlay, SearchButton, CloseIcon, SuggestionsList
} from './styles/header';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { getSchemas } from '../../helpers/schemas'
import DispatchContext from '../../context/DispatchContext'
import StateContext from '../../context/StateContext'
import * as authServices from '../../services/auth_services';
import * as ROUTES from '../../constants/routes';
import breakpoint from '../../constants/breakpoints'
import * as infoServices from '../../services/info_services'
import cgtfr_logo from '../../assets/logos/cgt_fr.svg'
import arrow_svg from '../../assets/logos/arrow-left.svg'


export default function Header({ children, ...restProps }) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    useEffect(() => {
        if (appState.loggedIn) {
            localStorage.setItem("isLoggedIn", true)
            getSchemas(appDispatch)
        } else {
            localStorage.removeItem("isLoggedIn")
            localStorage.removeItem('xsrfToken')
            localStorage.removeItem('user')
        }
    }, [appState.loggedIn, appDispatch])

    return (
        <HeaderNavbar {...restProps}>
            <HeaderNavbarLink>
                <a href={ process.env.REACT_APP_MAIN_URL } id="back-to-site">
                    <div class="inner-wrapper">
                        <img src={arrow_svg} alt="Arrow left" class="arrow-left"/>
                        <img src={cgtfr_logo} alt="Logo cgt.fr" />
                    </div>
                </a>
            </HeaderNavbarLink>
            {children}
        </HeaderNavbar>
    )
}

Header.Frame = function HeaderFrame({ children }) {
    return (
        <HeaderContainer>
            <GridContainer>
                {children}
            </GridContainer>
        </HeaderContainer>
    )
};

Header.LogoContainer = function HeaderLogoContainer({ to, ...restProps }) {

    return (
        <NavbarBrandContainer>
            <NavbarBrand className="mr-0 p-0" tag={A} href={ process.env.REACT_APP_MAIN_URL } to={to}>
                <HeaderLogo {...restProps}></HeaderLogo>
            </NavbarBrand>
            <Signature>{process.env.REACT_APP_SIGNATURE}</Signature>
        </NavbarBrandContainer>
    );
};

Header.Search = function HeaderSearch({ search, marker, fullscreen, setFullscreen, title, setTitle, location, setLocation, isOverlay }) {

    const { t } = useTranslation();
    const appDispatch = useContext(DispatchContext)
    let [suggestions, setSuggestions] = useState([])
    let [coordinates, setCoordinates] = useState()
    let timeoutId
    const locationRef = useRef('');

    const onChange = (e) => {
        e.persist()
        clearTimeout(timeoutId);

        if (e.target.name === "title") {
            setTitle(e.target.value)
            setLocation('')
            locationRef.current = ''
            if ((e.target.value.trim() === "" || e.target.value.trim().length > 2) && fullscreen === false)
                timeoutId = setTimeout(() => {
                    appDispatch({ type: "setTitleSearch", value: e.target.value })
                }, 2000);
        } else if (e.target.name === "location") {
            setLocation(e.target.value)
            setTitle('')
            setCoordinates(null)
            if ((e.target.value.trim() === "" || e.target.value.trim().length > 1))
                getSuggestions(e.target.value)
            if (fullscreen === false)
                appDispatch({ type: "setLocationSearch", value: e.target.value })
        }
    }

    const getSuggestions = (textValue) => {
        if (textValue.trim() === '')
            setSuggestions([])
        else if (locationRef.current !== textValue.trim()) {
            locationRef.current = textValue;
            infoServices.getInfo(`?select=["address", "locationGeoJSON"]&search={"address": { "$regex": "${textValue}" , "$options" : "i" }}&dateprint=true&limit=4`)
                .then((res) => {
                    setSuggestions(res.infos)
                })
                .catch((e) => {
                    appDispatch({ type: "setError", value: e })
                })
        }
    }

    const onFocus = (e) => {
        if (window.innerWidth < parseInt(breakpoint.size.md) && fullscreen === false) {
            e.target.blur()
            setFullscreen(true)
        }
    }

    const submit = () => {
        setFullscreen(false)
        if (title.trim() === "" && location.trim() === "")
            appDispatch({ type: "setTitleSearch", value: "" })
        else if (title.trim().length > 2)
            appDispatch({ type: "setTitleSearch", value: title })
        else if (location.trim().length > 2) {
            appDispatch({ type: "setLocationSearch", value: location })
            if (coordinates)
                appDispatch({ type: "centerUrl", value: coordinates })
        }
    }

    const selectAddress = (address) => {
        setLocation(address.address)
        // GeoJSON lng, lat
        let coord = { lat: address.locationGeoJSON.coordinates[1], lng: address.locationGeoJSON.coordinates[0] }
        if (fullscreen === false) {
            appDispatch({ type: "centerUrl", value: coord })
            appDispatch({ type: "setLocationSearch", value: address.address })
        } else
            setCoordinates(coord)
        setSuggestions([])
    }

    let showTitleInput = title.trim() !== '' || (location.trim() === '' && title.trim() === '')
    let showLocationInput = location.trim() !== ''

    return (
        <>
            <HideForm inline className="my-lg-0 position-relative" fullscreen={fullscreen.toString()} isoverlay={isOverlay ? isOverlay.toString() : "false"} show={showTitleInput.toString()}>
                <HeaderInput className="form-control" type="text" name="title" placeholder={t('Recherche Placeholder')} onChange={onChange} value={title} onFocus={onFocus} fullscreen={fullscreen.toString()} isoverlay={isOverlay ? isOverlay.toString() : "false"} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }} />
                <InputImage src={search} />
            </HideForm>
            <HideForm inline className="my-lg-0 position-relative" fullscreen={fullscreen.toString()} isoverlay={isOverlay ? isOverlay.toString() : "false"} show={showLocationInput.toString()}>
                <HeaderInput className="form-control" type="text" name="location" onChange={onChange} value={location} autoComplete="off" onFocus={onFocus} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }} />
                <InputImage src={marker} />
                {suggestions.length > 0 &&
                    <SuggestionsList>{suggestions.map((item) => <li key={item['_id']} onClick={() => selectAddress(item)}>{item.address}</li>)}</SuggestionsList>
                }
            </HideForm>
            {window.innerWidth < parseInt(breakpoint.size.md) && fullscreen === true && isOverlay === true &&
                <SearchButton onClick={submit}>{t('Lancer Recherche')}</SearchButton>
            }
        </>
    );
};

Header.Menu = function HeaderMenu({ chevron, user, userActive, loginIcon }) {

    const [isOpen, setIsOpen] = useState(false)
    const { t } = useTranslation();
    const appDispatch = useContext(DispatchContext)
    const appState = useContext(StateContext)

    const signOut = () => {
        authServices.logout()
            .finally(() => {
                appDispatch({ type: "logout" })
            })
    }

    return (
        <HeaderNavbarToggler onClick={() => setIsOpen(!isOpen)} isopen={isOpen.toString()}>
            <Img src={appState.loggedIn ? isOpen ? userActive : user : loginIcon} className="loginIcon" />
            {appState.loggedIn && <OnlineDot className="onlineDot" isopen={isOpen.toString()} />}
            <NavBarTitle>{appState.loggedIn ? t('Mon Compte') : t('Connecter')}</NavBarTitle>
            <HideImage src={chevron}></HideImage>
            <HeaderCollapse isOpen={isOpen}>
                <HeaderNav navbar className="mr-auto">
                    {appState.loggedIn
                        ?
                        <>
                            <NavItem>
                                <HeaderNavLink as={Link} to={ROUTES.PROFIL}>{t('Mon Profil')}</HeaderNavLink>
                            </NavItem>
                            <NavItem>
                                <HeaderNavLink onClick={signOut}>{t('Deconnecter')}</HeaderNavLink>
                            </NavItem>
                        </>
                        :
                        <NavItem>
                            <HeaderNavLink as={Link} to={ROUTES.SIGNIN}>{t('Connecter')}</HeaderNavLink>
                        </NavItem>
                    }
                </HeaderNav>
            </HeaderCollapse>
        </HeaderNavbarToggler>
    )
};

Header.Fullscreen = function HeaderFullscreen({ search, marker, fullscreen, setFullscreen, logo, close, ...restProps }) {

    const { t } = useTranslation();

    const closeOverlay = () => {
        setFullscreen(false)
    }

    return (
        <FullscreenOverlay fullscreen={fullscreen}>
            <CloseIcon src={close} onClick={closeOverlay} />
            <Img src={logo} />
            <Header.Search search={search} marker={marker} fullscreen={fullscreen} setFullscreen={setFullscreen} isOverlay={true} {...restProps} ></Header.Search>
            {fullscreen === false &&
                //placeholder to make the animation smoother
                <>
                    <Form inline className="my-lg-0 position-relative mt-3">
                        <HeaderInput className="form-control" type="text" name="location" />
                        <InputImage src={marker} />
                    </Form>
                    <SearchButton>{t('Lancer Recherche')}</SearchButton>
                </>
            }
        </FullscreenOverlay>
    )
};
