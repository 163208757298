import React, { useState, useEffect, useContext } from 'react';
import { PageContainer, SigninBox, FieldsContainer, SigninTitle, Label, SigninInput, SigninLink, SigninButton, CreateAccount, Spinner, EmailFeedback, InputContainer, SigninAlert, ModalContainer, CloseButton, ResetPass } from './styles/signin';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import DispatchContext from '../../context/DispatchContext'
import * as authServices from '../../services/auth_services';
import * as ROUTES from '../../constants/routes';
import closeIcon from '../../assets/svg/times-red-icon.svg'

export default function Signin({ children }) {
    return (
        <SigninBox>{children}</SigninBox>
    )
}

Signin.Container = function SigninContainer({ children }) {
    return (
        <PageContainer>
            {children}
        </PageContainer>
    )
};

Signin.Fields = function SigninFields({ newAccount, reset, toggleModal }) {

    const { t } = useTranslation();
    const appDispatch = useContext(DispatchContext)
    let history = useHistory();

    let [email, setEmail] = useState("")
    let [password, setPassword] = useState("")
    let [validEmail, setValidEmail] = useState("")
    let [validPass, setValidPass] = useState("")
    let [disabled, setDisabled] = useState(true)
    let [submitted, setSubmitted] = useState(false)
    let [alertSuccess, setAlertSuccess] = useState(false)

    //eslint-disable-next-line
    let isEmail = (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email));

    function checkValidEmail() {
        setValidEmail(isEmail)
    }

    function checkValidPass() {
        if (password.trim().length >= 8)
            setValidPass(true)
        else
            setValidPass(false)
    }

    function connect(e) {
        e.preventDefault();
        appDispatch({ type: "setError", value: null })
        setDisabled(true)
        setSubmitted(true)

        if (reset)
            authServices.requestNewPassword(email)
                .then(() => {
                    setAlertSuccess(true)
                    setSubmitted(false)
                    setTimeout(() => {
                        setAlertSuccess(false)
                        toggleModal()
                        setDisabled(false)
                    }, 3000);

                })
                .catch((e) => {
                    onError(e)
                    toggleModal()
                })
        else {
            if (newAccount)
                authServices.signup(email, password)
                    .then(() => {
                        onSuccess(false)
                    })
                    .catch((e) => {
                        onError(e)
                    })
            else
                authServices.signin(email, password)
                    .then((res) => {
                        onSuccess(true)
                    })
                    .catch((e) => {
                        onError(e)
                    })
        }
    }

    const onSuccess = (isLoggedIn) => {
        setAlertSuccess(true)
        if (isLoggedIn)
            appDispatch({ type: "login" })
        setDisabled(false)
        setSubmitted(false)
        setTimeout(() => {
            history.push(ROUTES.HOME);
        }, 2000);
    }

    const onError = (e) => {
        appDispatch({ type: "setError", value: e })
        setDisabled(false)
        setSubmitted(false)
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
        setValidEmail(true)
    }

    const handlePassChange = (e) => {
        setPassword(e.target.value)
        setValidPass(true)
    }

    useEffect(() => {
        let condition
        if (reset)
            condition = isEmail
        else
            condition = newAccount ? (isEmail && password.trim().length >= 8) : (isEmail && password.trim() !== '')
        if (condition)
            setDisabled(false)
        else
            setDisabled(true)
    }, [isEmail, password, newAccount, reset])

    return (
        <>
            <FieldsContainer>
                {reset ?
                    <SigninTitle>{t('Modif Password')}</SigninTitle>
                    :
                    <SigninTitle>{newAccount ? t('Creation Compte') : t('Connecter')}</SigninTitle>
                }
                <form onSubmit={connect}>
                    <InputContainer>
                        <Label htmlFor={reset ? "email_reset" : "email"}>{t('Email')} *</Label>
                        <SigninInput type="email" id={reset ? "email_reset" : "email"} name="email" value={email} valid={validEmail} onChange={handleEmailChange} placeholder={t('Email Placeholder')} onBlur={checkValidEmail} />
                        <EmailFeedback valid={validEmail}>{t('Email Invalide')}</EmailFeedback>
                    </InputContainer>
                    {!reset &&
                        <InputContainer>
                            <Label htmlFor="password">{t('Password')} *</Label>
                            <SigninInput type="password" id="password" name="password" value={password} valid={validPass} onChange={handlePassChange} placeholder={t('Password Placeholder')} {...(newAccount && { onBlur: checkValidPass })} />
                            <EmailFeedback valid={validPass}>{t('Password Invalide')}</EmailFeedback>
                        </InputContainer>
                    }
                    {!newAccount && !reset && <ResetPass onClick={toggleModal}>{t('Password Oublie')}</ResetPass>}
                    {reset ?
                        <SigninButton type="submit" disabled={disabled}><Spinner submitted={submitted} reset={reset} />{t('Reset Password')}</SigninButton>
                        :
                        <SigninButton type="submit" disabled={disabled} ><Spinner submitted={submitted} />{newAccount ? t('Bouton Registre') : t('Connecter')}</SigninButton>
                    }
                </form>
            </FieldsContainer>
            {reset ?
                <SigninAlert color="success" isOpen={alertSuccess} onClick={() => setAlertSuccess(false)}> {t('Reset Password Success')}</SigninAlert>
                :
                <SigninAlert color="success" isOpen={alertSuccess} onClick={() => setAlertSuccess(false)}> {newAccount ? t('Registre Success') : t('Signin Success')}</SigninAlert>
            }
        </>
    )
};

Signin.Register = function SigninRegister() {

    const { t } = useTranslation();

    return (
        <CreateAccount>{t('Pas De Compte')} <SigninLink to={ROUTES.SIGNUP}>{t('Creer Compte')}</SigninLink></CreateAccount>
    )
};

Signin.Modal = function SignInModal({ children, modal, toggleModal }) {

    return (
        <ModalContainer modal={modal}>
            <CloseButton src={closeIcon} onClick={toggleModal} />
            {children}
        </ModalContainer>
    )
}