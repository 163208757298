import React from 'react';
import { HeaderContainer } from '../containers/header'
import { SigninContainer } from '../containers/signin'

export default function Signup() {
    return (
        <>
            <HeaderContainer />
            <SigninContainer newAccount={true} />
        </>
    );
}