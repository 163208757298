import styled, { css } from 'styled-components';
import breakpoint from '../../../constants/breakpoints'

export const ListWrapper = styled.div`
    position: absolute;
    top: 2.5rem;
    right: 3.5rem;
    height: calc(100% - 5rem);
    background-color: ${props => props.theme.colors.input};
    width: 40%;
    box-shadow: 0px 4px 16px rgba(52, 58, 64, 0.16);
    border-radius: 4px;
    transition: transform .4s;
    display: grid;
    grid-template-rows: auto 64px;
    ${props => props.isHidden === true && css`
        transform: translateX(calc(100% + 0.5rem));
    `}
    ${props => props.showInfo === true && css`
        grid-template-rows: auto;
        background-color: white;
    `}
    @media ${breakpoint.device.md} {
        top: auto;
        bottom: 0;
        right: 1rem;
        height: 60%;
        width: calc(100% - 2rem);
        grid-template-rows: auto;
        ${props => props.showInfo === true && css`
            grid-template-rows: auto;
        `}
        ${props => props.isHidden === true && css`
            transform: translateY(calc(100% - 2.25rem));
        `}
    }
`

export const ListScroll = styled.div`
    height: 100%;
    overflow: auto;
    display: grid;
    ${props => props.infoOpen === true && css`
        grid-template-rows: auto auto 88px;
    `}
    @media ${breakpoint.device.md} {
        ${props => props.infoOpen === true && css`
            grid-template-rows: auto auto 64px;
        `}
    }

`

export const ScrollContainer = styled.div`
    overflow: hidden;
    border-radius: 4px 4px 0 0;
`

export const ListElement = styled.div`
    padding: 2rem 2.5rem;
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 2rem;
    cursor: pointer;
    &:hover {
        background-color: white;
        .address, .title {
            color: ${props => props.theme.colors.main};
            svg path {
                fill: ${props => props.theme.colors.main};
            }
        }
    }
    img {
        width: 100%;
        //height: 100%;
        min-height: 174px;
        object-fit: cover;
        background-color: ${props => props.theme.colors.defaultImage};
    }
    .error-image {
        object-fit: none;
    }
    @media ${breakpoint.device.md} {
        grid-template-columns: 1fr;
        gap: 0;
        padding: 1.5rem;
        img {
            display: none;
        }
    }
`

export const Address = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${props => props.theme.colors.content};
    font-family: 'AvenirNextMedium';
    ${props => props.color === "white" && css`
        color: white;
        svg path {
            fill: white;
        }
    `}
    svg {
        margin-right: 0.5rem;
        @media ${breakpoint.device.md} {
            transform: scale(0.75)
        }
    }
    @media ${breakpoint.device.md} {
        font-size: 0.625rem;
    }
`

export const ListTitle = styled.div`
    font-family: 'Metropolis';
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 2rem;
    color: ${props => props.theme.colors.title};
    margin: 1rem 0;
    ${props => props.color === "white" && css`
        color: white;
        line-height: 2.5rem;
        font-size: 1.5rem;
    `}
    @media ${breakpoint.device.md} {
        font-size: 1rem;
        line-height: 1.5rem;
    }
`

export const ListDetails = styled.div`
    font-family: 'Metropolis';
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: ${props => props.theme.colors.content};
    @media ${breakpoint.device.md} {
        font-size: 0.75rem;
        line-height: 1rem;
    }
`

export const Toggle = styled.div`
    background-color: ${props => props.theme.colors.input};
    border-radius: 4px 0px 0px 4px;
    width:38px;
    height:96px;
    position: absolute;
    top: 1rem;
    left: -38px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
        transform: scale(1.4);
        transition: transform .4s;
        ${props => props.isHidden === false && css`
            transform: scale(1.4)rotate(180deg);
        `}
        path {
            fill: ${props => props.theme.colors.content};
        }
    }

    @media ${breakpoint.device.md} {
        left: 50%;
        width: 24px;
        height: 72px;
        transform: rotate(90deg);
        border-radius: 4px 0px 0px 4px;
        top: -47px;
        svg {
            ${props => props.isHidden === false && css`
                transform: scale(1.4)rotate(180deg);
            `}
        }
    }
`

const buttonStyle = `
    border-radius: 4px;
    font-family: 'AvenirNextBold';
    font-size: 1.125rem;
    line-height: 2rem;
    color: white;
    border: 0;
    cursor: pointer;
`

export const AddButton = styled.button`
    ${buttonStyle}
    background-color: ${props => props.theme.colors.main};
    border-radius: 0px 0px 4px 4px;
    img {
        margin-right: 1rem;
    }
    :hover {
        background-color: ${props => props.theme.colors.mainHover};
    }
    @media ${breakpoint.device.md} {
        font-size: 0.875rem;
        line-height: 1.5rem;
        padding: 0.5rem 0;
        width: calc(100% - 3rem);
        left: 1.5rem;
        position: absolute;
        bottom: 1.5rem;
        border-radius: 4px;
        img {
            margin-right: 0.5rem;
            transform: scale(0.8)
        }
    }
`

export const EditButton = styled.button`
    ${buttonStyle}
    padding: 0 0.5rem;
    background-color: ${props => props.theme.colors.main};
    :hover {
        background-color: ${props => props.theme.colors.mainHover};
    }
    @media ${breakpoint.device.md} {
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
`

export const InfoHeader = styled.div`
    background: black;
    background-image: linear-gradient(0deg, rgba(52, 58, 64, 0.5), rgba(52, 58, 64, 0.5)), url(${props => `"${props.image}"`});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 2rem 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px 4px 0 0;
    .socials > button:nth-child(2){
        margin: 0 1.5rem;
    }
    @media ${breakpoint.device.md} {
        padding: 1.5rem;
        .socials{
            > button:nth-child(2) {
                margin: 0 1rem;
            }
            img {
                height: 16px;
                width: 16px;
            }
        }
    }
`

export const DescriptionContainer = styled.div`
    color: ${props => props.theme.colors.title};
    padding: 2rem 2.5rem;
    border-bottom: 2px solid ${props => props.theme.colors.input};
    @media ${breakpoint.device.md} {
        padding: 1.5rem;
    }
`

export const Description = styled.div`
    font-family: Metropolis;
    font-size: 1rem;
    line-height: 2rem;
    margin-bottom: 1rem;
    @media ${breakpoint.device.md} {
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
`

export const FilesContainer = styled.div`
    h5 {
        font-family: 'AvenirNext';
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: ${props => props.theme.colors.content};
        margin-bottom: 1rem;
    }
    a {
        text-decoration: none;
        color: inherit;
    }
    .pdf {
        display: flex;
        font-family: 'AvenirNext';
        font-size: 1rem;
        line-height: 1.5rem;
        :hover {
            color: ${props => props.theme.colors.main};
        }
        img {
            margin-right: 1rem;
        }
    }
    @media ${breakpoint.device.md} {
        h5 {
        font-size: 0.625rem;
        margin-bottom: 0.5rem;
    }
        .pdf {
            font-size: 0.825rem;
            img {
                margin-right: 0.5rem;
            }
        }
    }
`

export const PubDate = styled(ListDetails)`
    margin: auto 0;
    padding: 2rem 2.5rem;
    @media ${breakpoint.device.md} {
        padding: 1.5rem;
    }
`

export const Return = styled.div`
    font-family: 'AvenirNextMedium';
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 8rem;
    cursor: pointer;
    svg {
        margin-right: 1rem;
    }
    :hover {
        text-decoration: underline;
    }
    @media ${breakpoint.device.md} {
        margin-bottom: 2rem;
        font-size: 0.875rem;
    }
`

export const EmptyList = styled.div`
    padding: 2rem 2.5rem;
    font-family: 'Metropolis';
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 2rem;
    color: ${props => props.theme.colors.title};
    text-align: center;
    @media ${breakpoint.device.md} {
        font-size: 1rem;
        line-height: 1.5rem;
    }
`