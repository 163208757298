import React, { useContext, useEffect, useState, useRef } from 'react';
// import infos from '../fixtures/infos.json';
import { List } from '../components'
import plusIcon from '../assets/svg/plus-circle-icon.svg'
import StateContext from '../context/StateContext'
import * as infoServices from '../services/info_services'

export function ListContainer({ children }) {

    const appState = useContext(StateContext)
    let [infos, setInfos] = useState([])
    let [geoLocation, setGeoLocation] = useState()
    let [scrollHeight, setScrollHeight] = useState('100%')
    let [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const skipQuery = useRef(false);
    const titleRef = useRef('');
    const listRef = useRef(null);
    const buttonRef = useRef(null);

    // geoJSON format longitude / latitude
    const getGeoJSONCoordinates = (bounds) => {
        let { geoJsonBounds } = bounds
        return geoJsonBounds
    }

    useEffect(() => {
        const updateWindowDimensions = () => {
            setWindowWidth(window.innerWidth)
        };

        window.addEventListener("resize", updateWindowDimensions);

        return () => window.removeEventListener("resize", updateWindowDimensions)

    }, []);


    useEffect(() => {
        if(buttonRef && buttonRef.current) {
            let height = listRef.current.clientHeight - buttonRef.current.clientHeight
            if (window.innerWidth < 1024)
                height = height - 24 //padding bottom
            setScrollHeight(height + "px")
        } else {
            setScrollHeight(listRef.current.clientHeight + "px")
        }
    }, [windowWidth])

    useEffect(() => {
        if (titleRef.current !== appState.titleSearch && appState.titleSearch.length > 2) {
            skipQuery.current = true
            titleRef.current = appState.titleSearch
        }
    }, [appState.titleSearch])

    useEffect(() => {
        setInfos([])
    }, [appState.noResults])

    useEffect(() => {
        const getConditions = (bounds) => {
            let conditions = '?'
            let geowithin = {
                "locationGeoJSON": {
                    "$geoWithin": {
                        "$geometry": {
                            "type": "Polygon",
                            "coordinates": getGeoJSONCoordinates(bounds)
                        }
                    }
                }
            }
            conditions += `geowithin=${JSON.stringify(geowithin)}`
            conditions += `&limit=5&dateprint=true`
            if (appState.titleSearch && appState.titleSearch !== '')
                conditions += `&search={"title": { "$regex": "${appState.titleSearch}" , "$options" : "i" }}`
            else if (appState.locationSearch && appState.locationSearch !== '')
                conditions += `&search={"address": { "$regex": "${appState.locationSearch}" , "$options" : "i" }}`
            setGeoLocation(conditions)
            return conditions
        }
        if (skipQuery.current === true) {
            skipQuery.current = false
            return
        }
        if (appState.bounds) {
            infoServices.getInfo(getConditions(appState.bounds))
                .then((res) => {
                    setInfos(res.infos)
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    }, [appState.bounds, appState.titleSearch, appState.locationSearch]);

    return (
        <List showInfo={appState.activeMap !== null || appState.activeList !== null} listRef={listRef}>
            {(appState.activeMap == null && appState.activeList == null) ?
                <>
                    <List.Container infos={infos} conditions={geoLocation} scrollHeight={scrollHeight} />
                    <List.Button plusIcon={plusIcon} buttonRef={buttonRef} />
                </>
                :
                <List.Info active={appState.activeMap || appState.activeList} scrollHeight={scrollHeight} />}
            {children}
        </List>
    );
}