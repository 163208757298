import React from 'react';
import { ProfileContainer } from '../containers/profile'
import { HeaderContainer } from '../containers/header'

export default function Profile() {
    return (
        <>
            <HeaderContainer />
            <ProfileContainer />
        </>
    );
}