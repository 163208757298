import axios from 'axios';

const instance = axios.create({
    withCredentials: true
})

export const refreshToken = async () => {
    const savedToken = JSON.parse(localStorage.getItem('xsrfToken'));
    try {
        const response = await instance.post(`${process.env.REACT_APP_BACK_URL}/token`, { token: savedToken });
        const { xsrfToken } = response.data;
        localStorage.setItem('xsrfToken', JSON.stringify(xsrfToken));
        return response
    } catch (error) {
        throw error;
    }
};


export const getProfile = async () => {
    let config = {
        headers: {
            'x-xsrf-token': JSON.parse(localStorage.getItem('xsrfToken'))
        }
    }
    try {
        const response = await instance.get(`${process.env.REACT_APP_BACK_URL}/me`, config);
        return response.data
    } catch (error) {
        throw error;
    }
};