import React, { useState } from 'react';
import { Signin } from '../components';

export function SigninContainer({ newAccount }) {

    let [modal, setModal] = useState(false)

    const toggleModal = () => {
        setModal(old => !old)
    }

    return (
        <Signin.Container>
            <Signin>
                <Signin.Fields newAccount={newAccount} toggleModal={toggleModal} />
                {!newAccount && <Signin.Register />}
            </Signin>
            <Signin.Modal modal={modal} toggleModal={toggleModal}>
                <Signin>
                    <Signin.Fields reset={true} toggleModal={toggleModal} />
                </Signin>
            </Signin.Modal>
        </Signin.Container>
    );
}