import styled from 'styled-components';
import breakpoint from '../../../constants/breakpoints'

export const PageContainer = styled.div`
    background-color: ${props => props.theme.colors.input};
    padding: 2.5rem 0 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'AvenirNextMedium';
    @media ${breakpoint.device.md} {
        padding: 1rem 0;
    }
`

export const ProfileBox = styled.div`
    @media ${breakpoint.device.md} {
        width: calc(100% - 2rem)
    }
`

export const HeaderContainer = styled.div`
    text-align: center;
    margin-bottom: 2.5rem;
    display:flex;
    flex-direction: column;
    align-items: center;
`

export const UserIcon = styled.div`
    width: 112px;
    height: 112px;
    border-radius: 50%;
    background-color: #F1F3F5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    div {
        background-color: #ADB5BD;
        width: 56px;
        height: 56px;
        border-radius: 50%;
    }
    @media ${breakpoint.device.md} {
        width: 64px;
        height: 64px;
        div {
            width: 32px;
            height: 32px;
        }
    }
`

export const HeaderText = styled.h2`
    line-height: 2rem;
    color: ${props => props.theme.colors.title};
    margin-bottom: 1rem;
    @media ${breakpoint.device.md} {
        font-size: 1.125rem;
        line-height: 1.5rem;
    }
`

export const HeaderStatus = styled.div`
    font-family: 'AvenirNext';
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${props => props.theme.colors.input};
    padding: 0.25rem 0.5rem;
    background-color: ${props => props.theme.colors.status};
    border-radius: 4px;
    font-weight: 600;
    margin-bottom: 1rem;
    @media ${breakpoint.device.md} {
        font-size: 0.625rem;
    }
`

export const HeaderSignout = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
    text-decoration-line: underline;
    color: ${props => props.theme.colors.main};
    cursor: pointer;
    @media ${breakpoint.device.md} {
        font-size: 0.875rem;
    }
`

export const FieldsWrapper = styled.div`
    background-color: white;
    box-shadow: 0px 4px 16px rgba(52, 58, 64, 0.16);
    border-radius: 4px;
    margin-bottom: 2rem;
    @media ${breakpoint.device.md} {
        margin-bottom: 1rem;
    }
`

export const FieldsContainer = styled.div`
    padding: 2rem 2.5rem;
    @media ${breakpoint.device.md} {
        padding: 1.5rem;
    }
`

export const FieldsTitle = styled.div`
    font-family: 'AvenirNextBold';
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${props => props.theme.colors.title};
    margin-bottom: 2rem;
    @media ${breakpoint.device.md} {
        font-size: 1.125rem;
        line-height: 1.5rem;
    }
`

export const InputContainer = styled.div`
    margin-bottom: 1.5rem;
`

export const Label = styled.label`
    display: block;
    color: ${props => props.theme.colors.content};
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 1rem;
    @media ${breakpoint.device.md} {
        font-size: 0.625rem;
    }
`

export const ProfileInput = styled.input`
    background-color: ${props => props.theme.colors.input};
    border-radius: 4px;
    padding: 1.25rem 1.5rem;
    border: none;
    min-width: 488px;
    font-family: 'AvenirNext';
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: ${props => props.theme.colors.content};
    border: 1px solid transparent;
    @media ${breakpoint.device.md} {
        min-width: auto;
        width: 100%;
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
    }
`

export const ProfileButton = styled.button`
    background-color: ${props => props.theme.colors.main};
    font-family: 'AvenirNextBold';
    font-size: 1.125rem;
    line-height: 2rem;
    color: white;
    border: 0;
    width: 100%;
    padding: 1rem 0;
    cursor: pointer;
    border-radius: 0 0 4px 4px;
    :hover {
        background-color: ${props => props.theme.colors.mainHover};
    }
    @media ${breakpoint.device.md} {
        font-size: 0.875rem;
        line-height: 1.5rem;
        padding: 0.5rem 0;
        margin: 1.5rem;
        width: calc(100% - 3rem);
        margin-top: 0;
        border-radius: 4px;
    }
`

export const ProfileRemove = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${props => props.theme.colors.main};
    text-align: left;
    cursor: pointer;
    @media ${breakpoint.device.md} {
        font-size: 0.875rem;
    }
`