import React, { useEffect }from 'react';
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import { MessageContainer } from '../containers/message';



export default function IsVerified() {
    let history = useHistory();

    useEffect(() => {
        const timer = setTimeout(() => {
                history.push(ROUTES.HOME);
            }, 6000);
        return () => clearTimeout(timer);
      }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <MessageContainer />
      );
}