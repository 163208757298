export const errorHelper = (e) => {

    let errorText
    let logout = false
    let showError = false
    let refreshToken = false

    if (e.response && e.response.status === 401 && (e.response.data.name === "bad_credentials" || e.response.data.name === "mail_already_exists") && e.response.config.url.includes("signin")) {
        errorText = "Signin Erreur"
        showError = true
    }
    else if (e.response && e.response.status === 401 && (e.response.data.name === "bad_credentials" || e.response.data.name === "mail_already_exists") && e.response.config.url.includes("signup")) {
        errorText = "Registre Erreur"
        showError = true
    }
    else if (e.response && (e.response.config.url.includes("signup") || e.response.config.url.includes("signin"))) {
        errorText = "Erreur Info Text"
        showError = true
    }
    else if (e.response && e.response.data.status === 401 && e.response.data.name === "bad_credentials") {
        errorText = 'Erreur Credentials'
        logout = true
        showError = true
        localStorage.removeItem("isLoggedIn")
        localStorage.removeItem('xsrfToken')
        localStorage.removeItem('user')
    }
    else if (e.response && e.response.data.status === 401 && e.response.data.name === "expired_token")
        refreshToken = true
    else if (e.response && e.response.data.status === 400 && e.response.data.name === "invalid_file_type") {
        errorText = "Fichier Erreur"
        showError = true
    }
    else if (e.response && e.response.data.status === 400 && e.response.data.name === "invalid_file_size") {
        errorText = "invalid_file_size"
        showError = true
    }
    else if (e.response && e.response.data.status === 403 && e.response.data.name === "user_is_forbidden") {
        errorText = "user_is_forbidden"
        showError = true
    }
    else if (e.response && e.response.data.status === 404 && e.response.data.name === "user_not_found") {
        errorText = 'user_not_found'
        showError = true
    }
    else if (e.response && e.response.data.status === 401 && e.response.data.name === "expired_verify_token") {
        errorText = 'expired_verify_token'
        showError = true
    }
    else if (e.response && e.response.data.status === 500) {
        errorText = "Erreur Info"
        showError = true
    }
    else {
        errorText = "Erreur Info Text"
        showError = true
    }

    return { showError, logout, errorText, refreshToken }
}