import React from 'react';
import { MapContainer } from '../containers/map'
import { HeaderContainer } from '../containers/header'
import { useMatomo } from '@datapunt/matomo-tracker-react'

export default function Home() {
  const { trackPageView } = useMatomo()
  // Track page view
  React.useEffect(() => {
    trackPageView() // eslint-disable-next-line
  }, [])
  return (
    <>
      <HeaderContainer />
      <MapContainer />
    </>
  );
}