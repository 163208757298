export const initialState = {
    loggedIn: Boolean(localStorage.getItem("isLoggedIn")),
    activeMap: null,
    activeList: null,
    hoverList: null,
    center: {
        lat: null,
        lng: null
    },
    searchBounds: null,
    noResults: 0,
    panMap: 0,
    resetTitle: 0,
    infoOpen: false,
    infoData: null,
    newMarker: {
        lat: null,
        lng: null,
        source: null
    },
    newInfoStatus: null,
    bounds: null,
    error: null,
    titleSearch: '',
    locationSearch: '',
    tags: [],
    markers: []
}

export function ourReducer(draft, action) {
    switch (action.type) {
        case "login":
            draft.loggedIn = true
            return
        case "logout":
            draft.loggedIn = false
            return
        case "markerClicked":
            draft.activeMap = action.value
            let markerCoord = action.value?.locationGeoJSON?.coordinates
            draft.center = { lat: markerCoord?.[1], lng: markerCoord?.[0] }
            draft.activeList = null
            draft.panMap++
            return
        case "listClicked":
            draft.activeList = action.value
            let listCoord = action.value?.locationGeoJSON?.coordinates
            draft.center = { lat: listCoord[1], lng: listCoord[0] }
            draft.activeMap = null
            draft.panMap++
            return
        case "listHovered":
            draft.hoverList = action.value
            return
        case "center":
            draft.center = action.value
            return
        case "centerUrl":
            draft.center = action.value
            draft.panMap++
            return
        case "setSearchBounds":
            draft.searchBounds = action.value
            return
        case "noSearchBounds":
            draft.noResults++
            return
        case "resetTitle":
            draft.resetTitle++
            return
        case "resetMap":
            draft.activeMap = action.value
            draft.hoverList = action.value
            draft.activeList = action.value
            return
        case "resetList":
            draft.activeList = action.value
            draft.hoverList = action.value
            draft.activeMap = action.value
            return
        case "addInfo":
            draft.infoOpen = action.value
            draft.activeMap = null
            draft.newMarker.lat = null
            draft.newMarker.lng = null
            draft.newInfoStatus = null
            return
        case "editInfo":
            draft.infoOpen = action.value.status
            draft.infoData = action.value.data
            draft.activeMap = null
            draft.newMarker.lat = null
            draft.newMarker.lng = null
            draft.newInfoStatus = null
            return
        case "addMarker":
            draft.newMarker = action.value
            return
        case "infoSubmitted":
            draft.newInfoStatus = action.value
            return
        case "infoAddressClicked":
            draft.center = action.value
            draft.panMap++
            return
        case "setBounds":
            draft.bounds = action.value
            return
        case "setError":
            draft.error = action.value
            return
        case "setTitleSearch":
            draft.titleSearch = action.value
            draft.locationSearch = ''
            return
        case "setLocationSearch":
            draft.locationSearch = action.value
            draft.titleSearch = ''
            return
        case "setTags":
            draft.tags = action.value
            return
        case "setLimitSizeUploadMedia":
            draft.limitSizeUploadMedia = action.value
            return
        case "setLimitSizeUploadPdf":
            draft.limitSizeUploadPdf = action.value
            return
        case "setMarkers":
            draft.markers = action.value
            return
        default:
            return
    }
}